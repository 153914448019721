export const AD_TESTING_COOKIE = 'angel_ad_testing'
export const AUTH_JWT_COOKIE = 'angel_jwt'
export const AUTH_SESSION_COOKIE = 'angelSession'
export const AUTH_SESSION_COOKIE_0 = 'angelSession.0'
export const EARLY_ACCESS_COOKIE = '_angel_early_access'
export const ENABLE_BETA_LANGUAGE_COOKIE = 'ANGEL_BETA_LANGUAGE'
export const FULL_STORY_ENABLED_COOKIE = 'angel_fullstory_enabled'
export const SEGMENT_ANONYMOUS_ID_COOKIE = 'ajs_anonymous_id'
export const SEGMENT_USER_ID_COOKIE = 'ajs_user_id'
export const UPDATED_LEGAL_DOCUMENTS = 'angel_legal_docs'
export const LOGIN_STARTED_ORIGIN = 'login_started_origin'
export const LOGIN_USER_EMAIL = 'login_user_email'
export const CONTENT_SHARED_ID = 'content_shared_id'
export const EVERFLOW_TRANSACTION_ID_COOKIE = '_ef_transaction_id'
