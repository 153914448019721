import { PropsWithChildren, memo, useEffect } from 'react'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import { logger } from '@/utils/logging'
import { AppFeatures } from '../app-features'

interface Props extends PropsWithChildren {
  growthbook: GrowthBook<AppFeatures>
}

const Provider: React.FC<Props> = ({ growthbook, children }) => {
  useEffect(() => {
    growthbook.init().catch((error) => {
      logger().warn('Growthbook Failed to Load Features - Retrying 1 time', { growthbook }, error)
      growthbook.init().catch((error) => {
        logger().error('Growthbook Failed to Load Features on Retry', { growthbook }, error)
      })
    })
  }, [growthbook])

  return <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
}

export const ExperimentContextProvider = memo(Provider)
