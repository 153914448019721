import React from 'react'
import { LanguagesProvider } from '@/molecules/LanguageSelector'
import { DesktopSiteNav } from '@/organisms/SiteNav/DesktopSiteNav'
import { MobileSiteNav } from '@/organisms/SiteNav/MobileSiteNav/MobileSiteNav'
import { SiteNavContextProvider } from '@/organisms/SiteNav/SiteNavContext'
import { useGuildMember } from '@/services/GuildUserService'
import { TheatricalReleaseRegion } from '@/types/codegen-federation'
import { useOutageAlert } from '@/utils/useOutageAlert'

export interface SiteNavProps {
  hideNavigation?: boolean
  gradientBackground?: boolean
  transparentBackground?: boolean
  regions?: TheatricalReleaseRegion[]
  hideMobileNavigation?: boolean
  variant?: 'sticky' | 'absolute'
}

export const SiteNav: React.FC<SiteNavProps> = ({
  hideNavigation = false,
  gradientBackground = false,
  transparentBackground = false,
  hideMobileNavigation = false,
  regions,
  variant = 'absolute',
}) => {
  const { isGuildMember } = useGuildMember()
  useOutageAlert()
  return (
    <LanguagesProvider>
      <SiteNavContextProvider
        gradientBackground={gradientBackground}
        hideNavigation={hideNavigation}
        hideMobileNavigation={hideMobileNavigation}
        regions={regions}
        transparentBackground={transparentBackground}
        variant={variant}
      >
        <MobileSiteNav isGuildMember={isGuildMember} />
        <DesktopSiteNav isGuildMember={isGuildMember} />
      </SiteNavContextProvider>
    </LanguagesProvider>
  )
}
