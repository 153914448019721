import { type BranchError } from 'branch-sdk'
import { namedLogger } from '../utils'
import { type AnalyticsPlugin } from './types'

const logger = namedLogger('branch Analytics Plugin', {
  // debug: true,
})

export function branchAnalyticsPlugin() {
  let isInitialized = false

  const plugin: AnalyticsPlugin<'branchFingerPrint'> = {
    name: 'branch',
    branchFingerPrint: null,
    load() {
      logger.debug('load')
      // Initialize here too since an initial page load isn't caught in the route events.  This makes sure
      // we can send track events before the next route event occurs
      if (typeof window === 'undefined') return
      branchInitAndFetch()
        .then((branchFingerPrint) => {
          logger.debug('setBranchFingerPrint', { branchFingerPrint })
          localStorage.setItem('browser_fingerprint_id', branchFingerPrint as string)
          this.branchFingerPrint = branchFingerPrint
          isInitialized = true
        })
        .catch(logger.error)
    },

    loaded() {
      logger.debug('loaded')
      return isInitialized
    },
  }

  return plugin
}

async function branchInitAndFetch() {
  const BranchSDK = (await import('branch-sdk')).default
  return new Promise((resolve, reject) => {
    BranchSDK.init(
      process.env.NEXT_PUBLIC_BRANCH_KEY as string,
      { timeout: 8000, retry_delay: 500 },
      (error: BranchError) => {
        if (error) {
          reject(error)
        } else {
          BranchSDK.getBrowserFingerprintId((error, data) => {
            if (error) {
              reject(error)
            } else {
              resolve(data)
            }
          })
        }
      },
    )
  })
}
