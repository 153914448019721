/* eslint-disable @typescript-eslint/no-explicit-any */
import { getCookie } from 'cookies-next'
import { SEGMENT_ANONYMOUS_ID_COOKIE, SEGMENT_USER_ID_COOKIE } from '../../constants/cookies'
import { isServer } from '../client'
import { isDevelopmentEnvironment, isProductionEnvironment, isTestEnvironment } from '../environment-utils'
import { getConsoleLogger } from './consoleLogger'
import { getDatadogLogger } from './datadogLogger'
import { getNoopLogger, Logger } from './shared'

let logger: Logger

/**
 * Short for "client logger".
 * @deprecated Do not use directly. Use logger() instead.
 */
export function cLogger() {
  if (!logger) {
    logger = initLogger()
  }
  return logger
}

function initLogger(): Logger {
  const fallbackLogger = isProductionEnvironment() ? getNoopLogger() : getConsoleLogger(getDefaultContext)

  if (isTestEnvironment()) {
    return getNoopLogger()
  }

  if (isDevelopmentEnvironment()) {
    return fallbackLogger
  }

  if (isServer()) {
    fallbackLogger.warn('Tried to initialize the client side logger on the server. Falling back to a console logger.')
    return fallbackLogger
  }

  try {
    return getDatadogLogger(getDefaultContext)
  } catch (err) {
    fallbackLogger.error('Failed to initialize the datadog logger. Falling back to a console logger.', err)
    return fallbackLogger
  }
}

function getDefaultContext() {
  return {
    anonymousId: getCookie(SEGMENT_ANONYMOUS_ID_COOKIE),
    userId: getCookie(SEGMENT_USER_ID_COOKIE),
  }
}
