import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const TriangleArrowRight: React.FC<OneColorIconProps> = ({ className, color = 'core-oxide' }) => {
  const hex = getHexColor(color)
  return (
    <svg className={className} width="9" height="22" viewBox="0 0 9 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 11 0 21.392V.608L9 11Z" fill={hex} />
    </svg>
  )
}

export default TriangleArrowRight
