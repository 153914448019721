import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import locales from '@/constants/locales'
import { getItemOrFirstEntry } from '@/utils/array-utils'

/**
 * If we ever are able to get @/constants/locales to be a ts file we should
 * build this type from the typeof locales['all'][number]
 */
export type Locale =
  | 'en'
  | 'es'
  | 'pt-BR'
  | 'de-DE'
  | 'fr'
  | 'zh-CN'
  | 'it'
  | 'pl'
  | 'zh-Hant'
  | 'ach-UG'
  | 'es-ES'
  | 'no'
  | 'fi'
  | 'sv'
  | 'nl'

type UseLocaleResponse = {
  locale: Locale
}

const ALL_LOCALES_LOWERCASE = {} as Record<string, Locale>

locales.all.forEach((lng) => (ALL_LOCALES_LOWERCASE[lng.toLowerCase()] = lng))

export const useLocale = (): UseLocaleResponse => {
  const { asPath } = useRouter()
  return useMemo(() => {
    return {
      locale: getLocaleFromPath(asPath) as Locale,
    }
  }, [asPath])
}

/**
 * This is useful in getStaticProps but shouldn't be used elsewhere
 * because rewrites will have the params of the path you rewrite to,
 * not the params of the URL in your URL bar
 * @param params
 */
export const getLocaleFromParams = (params: ParsedUrlQuery | undefined): Locale => {
  return getLocaleOrDefault(params?.locale)
}

export function getLocaleFromPath(path: string): Locale {
  if (isFirstPathParamLocale(path)) {
    return ALL_LOCALES_LOWERCASE[getPotentialLocaleFromPath(path).toLowerCase()]
  } else {
    return locales.defaultLocale as Locale
  }
}

/**
 * Don't use this for rewrites because it doesn't have the /en path
 * that is needed for rewrites!
 * @param path
 */
export function getLocaleSectionForRedirectPath(path: string): string {
  const locale = getLocaleFromPath(path)
  return locale === locales.defaultLocale ? '' : `/${locale}`
}

export function getPotentialLocaleFromPath(path: string): string {
  const split = path?.split(/[/?]/)
  return split[1]
}

export function isFirstPathParamLocale(path: string) {
  const pathLocale = getPotentialLocaleFromPath(path)
  return !!ALL_LOCALES_LOWERCASE[pathLocale.toLowerCase()]
}

export function getLocaleOrDefault(locale: string | string[] | undefined): Locale {
  const localeFromQuery = getItemOrFirstEntry(locale)
  if (localeFromQuery) {
    return (ALL_LOCALES_LOWERCASE[localeFromQuery?.toLowerCase()] ? localeFromQuery : locales.defaultLocale) as Locale
  } else {
    return locales.defaultLocale as Locale
  }
}

export function getYouTubeLocaleLink(locale: string): string {
  switch (locale) {
    case 'pt-BR':
      return 'https://www.youtube.com/@AngelStudiosBrasil?sub_confirmation=1'
    case 'hi-IN':
      return 'https://www.youtube.com/@AngelStudiosIndia?sub_confirmation=1'
    case 'en-GB':
    case 'en-IE':
      return 'https://www.youtube.com/@AngelStudiosUK?sub_confirmation=1'
    case 'tl-PH':
      return 'https://www.youtube.com/@AngelStudiosPhilippines?sub_confirmation=1'
    case 'en-GH':
    case 'en-LR':
    case 'en-NG':
      return 'https://www.youtube.com/@AngelStudiosWestAfrica?sub_confirmation=1'
    case 'es-419':
    case 'es-MX':
    case 'es-AR':
    case 'es-CO':
    case 'es-PE':
    case 'es-VE':
    case 'es-CL':
    case 'es-EC':
    case 'es-GT':
    case 'es-CU':
    case 'es-BO':
    case 'es-DO':
    case 'es-HN':
    case 'es-PY':
    case 'es-SV':
    case 'es-NI':
    case 'es-CR':
    case 'es-PA':
    case 'es-UY':
      return 'https://www.youtube.com/@AngelStudiosEnespanol?sub_confirmation=1'
    default:
      return 'https://www.youtube.com/@angelstudiosinc?sub_confirmation=1'
  }
}
