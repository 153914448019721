import { FC, useState } from 'react'
import { Listbox } from '@headlessui/react'
import classNames from 'classnames'
import { CaretDownIcon } from '@/atoms/Icons/CaretDownIcon'
import { TranslationIcon } from '@/atoms/Icons/TranslationIcon'
import { TitleXS } from '@/atoms/Text'
import { useThemeContext } from '@/contexts/ThemeContext'
import { useLanguages } from '@/molecules/LanguageSelector'
import { LanguageHeader } from '@/molecules/LanguageSelector/LanguageHeader'
import { LanguageOption } from '@/molecules/LanguageSelector/LanguageOption'
import { useLocale } from '@/utils/LocaleUtil'
import { useTranslate } from '@/utils/translate/translate-client'

interface LanguageAccordionProps {
  onClose: () => void
}

export const LanguageAccordion: FC<LanguageAccordionProps> = ({ onClose }) => {
  const { t } = useTranslate('common')
  const [isOpen, setIsOpen] = useState(false)
  const { betaLanguages, publicLanguages, saveLanguage, languagesEnabled, betaLanguagesEnabled } = useLanguages()
  const { isDarkMode } = useThemeContext()

  const { locale } = useLocale()

  const handleOnChange = (language: string) => {
    onClose()
    setIsOpen(false)
    saveLanguage(language)
  }

  if (!languagesEnabled) {
    return null
  }

  return (
    <Listbox as="div" onChange={handleOnChange} value={locale}>
      <Listbox.Button onClick={() => setIsOpen(!isOpen)} className="w-full">
        <div
          className={classNames(
            isDarkMode ? 'hover:bg-white/10' : 'hover:bg-core-gray-950/[0.08]',
            'flex flex-row items-center space-x-2 p-2 hover:rounded-md md:px-4 py-3',
          )}
        >
          <div className="w-fit">
            <TranslationIcon size={24} color="gray-600" className="m-auto" />
          </div>
          <div className="flex w-full justify-between">
            <TitleXS className={isDarkMode ? 'text-white ' : 'text-gray-950'} weight="normal">
              {t('languages', 'Languages')}
            </TitleXS>
            <CaretDownIcon
              size={24}
              className={classNames({ 'rotate-180': isOpen })}
              color={isDarkMode ? 'white' : 'gray-950'}
            />
          </div>
        </div>
      </Listbox.Button>
      {isOpen && (
        <Listbox.Options static>
          <div className="max-h-36 overflow-y-auto pl-8">
            {publicLanguages.map((language) => (
              <Listbox.Option key={language} value={language}>
                {({ selected }) => <LanguageOption isDarkMode={isDarkMode} language={language} selected={selected} />}
              </Listbox.Option>
            ))}
            {betaLanguagesEnabled && (
              <>
                <div className={classNames('border border-t-0 border-l-0 border-r-0 border-b-1 mt-3 mb-5')} />
                <div className="mb-4">
                  <LanguageHeader isDarkMode={isDarkMode} value={t('betaLanguages', 'Beta Languages')} />
                </div>
                {betaLanguages.map((language) => (
                  <Listbox.Option key={language} value={language}>
                    {({ selected }) => (
                      <LanguageOption isDarkMode={isDarkMode} language={language} selected={selected} />
                    )}
                  </Listbox.Option>
                ))}
              </>
            )}
          </div>
        </Listbox.Options>
      )}
    </Listbox>
  )
}
