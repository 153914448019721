import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import classNames from 'classnames'
import { format, parseISO } from 'date-fns'
import { useRouter } from 'next/router'
import { isMobile } from 'react-device-detect'
import { ReactRef } from 'use-callback-ref/dist/es5/types'
import { FlexColumn, FlexRow } from '@/atoms/FlexContainers'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { GiftIcon } from '@/atoms/Icons/GiftIcon'
import { TicketIcon } from '@/atoms/Icons/TicketIcon'
import { Image } from '@/atoms/Image'
import { CaptionMD, TitleXS } from '@/atoms/Text'
import { paths } from '@/constants/paths'
import { useIsDarkMode } from '@/contexts/ThemeContext'
import {
  GetTheatricalReleasesForFeatureQuery,
  GetTheatricalReleasesForFeatureQueryVariables,
} from '@/types/codegen-federation'
import { useLocale } from '@/utils/LocaleUtil'
import { useSafeAnalytics } from '@/utils/analytics'
import { getBooleanFromLocalStorage, writeToLocalStorage } from '@/utils/local-storage'
import { useTranslate } from '@/utils/translate/translate-client'
import { ShareGuildTicketsModal } from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/ShareGuildTickets'
import { useGuildTickets } from '@/views/TicketCheckoutViews/hooks/useGuildTickets'

export const GET_THEATRICAL_RELEASES_FOR_FEATURE = gql`
  query getTheatricalReleasesForFeature($input: TheatricalReleasesInput!) {
    theatricalReleases(input: $input) {
      theatricalRelease {
        id
        theatricalSlug
        isFeaturedRelease
        title
        verticalPoster
        releaseDate
      }
    }
  }
`

interface Props {
  className?: string
  isDismissable?: boolean
  orientation?: 'left' | 'right'
  modalRef?: ReactRef<HTMLDivElement>
}

export const GuildRedeemTicketsCard: FC<Props> = ({
  className,
  isDismissable = false,
  orientation = 'right',
  modalRef,
}) => {
  const { t } = useTranslate('common')
  const [isGuildShareModalOpen, setIsGuildShareModalOpen] = useState(false)
  const [isDismissed, setIsDismissed] = useState<boolean | null>(null)
  const { locale } = useLocale()
  const { push } = useRouter()
  const { track } = useSafeAnalytics()
  const isDarkMode = useIsDarkMode()

  const { data } = useQuery<GetTheatricalReleasesForFeatureQuery, GetTheatricalReleasesForFeatureQueryVariables>(
    GET_THEATRICAL_RELEASES_FOR_FEATURE,
    { variables: { input: { language: locale } }, errorPolicy: 'all' },
  )
  const releaseToPromote = useMemo(() => {
    return data?.theatricalReleases?.theatricalRelease?.find(
      (theatricalRelease) => theatricalRelease?.isFeaturedRelease,
    )
  }, [data?.theatricalReleases?.theatricalRelease])

  const theatricalSlug = releaseToPromote?.theatricalSlug as string
  const storageKey = `${theatricalSlug}-guild-ticket-dismissed`

  const { discountCodes, areGuildTicketsAvailable, giftGuildTicketsMobile } = useGuildTickets(
    releaseToPromote?.theatricalSlug as string,
  )

  const formattedDateString = useMemo(() => {
    const releaseDate = releaseToPromote?.releaseDate
    if (!releaseDate) return null

    const date = parseISO(releaseDate)
    return format(date, 'MMMM d, yyyy')
  }, [releaseToPromote?.releaseDate])

  const handleShareGuildDiscount = useCallback(async () => {
    if (isMobile) return giftGuildTicketsMobile()

    setIsGuildShareModalOpen(true)
  }, [giftGuildTicketsMobile])

  const handleClaimTickets = useCallback(() => {
    if (!theatricalSlug) return null

    const url = paths.tickets.checkout.showtimes(theatricalSlug)
    push(url)
  }, [push, theatricalSlug])

  const onDismiss = useCallback(() => {
    if (!storageKey) return null
    writeToLocalStorage(storageKey, true)
    setIsDismissed(true)
  }, [storageKey])

  useEffect(() => {
    if (!storageKey) return

    const isGuildCardDismissed = getBooleanFromLocalStorage(storageKey)
    setIsDismissed(!!isGuildCardDismissed)
  }, [storageKey])

  if (!releaseToPromote || !areGuildTicketsAvailable) return null
  if (isDismissable && (isDismissed == null || isDismissed)) return null

  return (
    <>
      <div
        className={classNames('relative w-full rounded-2xl p-[1px] @container', className)}
        style={{
          backgroundImage:
            'linear-gradient(166.78deg, #EDD7C6 1.42%, #D6A177 14.6%, #FEDEC6 39.98%, #D48D59 60.48%, #A66141 85.7%, #612B12 94.6%)',
        }}
      >
        {isDismissable && (
          <div
            className={classNames(
              'absolute -right-2 -top-2 z-20 cursor-pointer rounded-full bg-core-gray-200 p-1',
              isDarkMode ? 'bg-white/20 backdrop-blur-md' : 'bg-black/10',
            )}
            onClick={onDismiss}
          >
            <CloseIcon color={isDarkMode ? 'white' : 'black'} size={16} />
          </div>
        )}
        <div
          style={{
            background: isDarkMode
              ? 'linear-gradient(103.7deg, #2B2014 0%, #191209 100%)'
              : 'linear-gradient(98.98deg, #FFFFFF 0%, #FFF4EB 100%)',
          }}
          className={classNames(
            'relative flex items-center justify-between gap-3 rounded-2xl p-4',
            isDarkMode ? 'text-white' : 'text-core-gray-950',
            orientation === 'left' && 'flex-row-reverse',
          )}
        >
          <FlexColumn className="gap-2">
            <TitleXS weight="semibold">
              {t('movieTicketsGuildCard', {
                count: discountCodes.length,
                defaultValue_one: 'You have one available Guild ticket.',
                defaultValue_other: 'You have two available Guild tickets.',
              })}
            </TitleXS>
            {formattedDateString && (
              <CaptionMD color={isDarkMode ? 'core-gray-400' : 'core-gray-700'}>
                {t('inTheatersReleaseDate', 'In Theaters {{dateString}}', { dateString: formattedDateString })}
              </CaptionMD>
            )}
            <FlexRow className="mt-1 gap-2">
              <button
                onClick={() => {
                  track('Claim Guild Tickets Clicked')
                  handleClaimTickets()
                }}
                className={classNames(
                  'flex items-center gap-1 rounded-lg !border-none px-3 py-1.5 @sm:py-3 @sm:px-4 !text-xs font-semibold',
                  isDarkMode ? '!bg-white/20' : '!bg-black !text-white',
                )}
              >
                <TicketIcon size={16} color="white" />
                {t('claimTickets', 'Claim Tickets')}
              </button>
              <button
                onClick={() => {
                  track('Gift Guild Tickets Clicked')
                  handleShareGuildDiscount()
                }}
                className={classNames(
                  'flex items-center gap-1 rounded-lg !border-none px-3 py-1.5 @sm:py-3 @sm:px-4 !text-xs',
                  isDarkMode ? '!bg-white/20' : '!bg-core-gray-300',
                )}
              >
                <GiftIcon size={16} color={isDarkMode ? 'white' : 'core-gray-950'} />
                <span className="hidden font-semibold @sm:inline"> {t('gift', 'Gift')}</span>
              </button>
            </FlexRow>
          </FlexColumn>
          <div className="aspect-[2/3] h-full bg-red" style={{ aspectRatio: 2 / 3 }} />
          {releaseToPromote?.verticalPoster && (
            <Image
              className="!h-full shrink-0 rounded-lg @sm:h-[123px] @sm:w-[82px]"
              style={{ aspectRatio: 2 / 3 }}
              alt="poster"
              src={releaseToPromote.verticalPoster}
              width={70}
              height={100}
            />
          )}
        </div>
      </div>
      <ShareGuildTicketsModal
        modalRef={modalRef}
        open={isGuildShareModalOpen}
        onClose={() => {
          setIsGuildShareModalOpen(false)
        }}
        theatricalSlug={theatricalSlug}
        projectName={releaseToPromote?.title as string}
        discountCodes={discountCodes}
      />
    </>
  )
}
