/* eslint-disable @typescript-eslint/no-explicit-any */
import { Logger as DatadogLogger, datadogLogs, LogsEvent, LogsEventDomainContext } from '@datadog/browser-logs'
import { getAngelEnvironment } from '../environment-utils'
import type { DefaultContextFn, SendLogMessagePayload } from './shared'
import { createLogger, getConfiguredLogLevel, getErrorFromContext, isAnError, Logger } from './shared'

export function getDatadogLogger(getDefaultContext: DefaultContextFn): Logger {
  const CLIENT_TOKEN = process.env.NEXT_PUBLIC_DATADOG_LOGGING_CLIENT_TOKEN
  if (!CLIENT_TOKEN) {
    throw new Error('Missing CLIENT_TOKEN required for datadog logging.')
  }

  // See https://docs.datadoghq.com/logs/log_collection/javascript/
  datadogLogs.init({
    clientToken: CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'angel-web',
    env: getAngelEnvironment(),
    version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    beforeSend: filterLogEvents,
  })

  const logger = datadogLogs.createLogger('angel-web-client', {
    level: getConfiguredLogLevel(),
    handler: 'http',
  })

  return createLogger({ getDefaultContext, logger, sendLogMessage })
}

const HOSTS_TO_IGNORE: string[] = ['api.segment.io', 'tattle.api.osano.com', 'logx.optimizely.com', 'o.clarity.ms']

const LOG_MESSAGES_TO_IGNORE: RegExp[] = [
  /\[OPTIMIZELY\] - ERROR.*/,
  /.*Object Not Found Matching Id:.*/,
  /.*Script error.*/,
  /\[bugsnag\] (Session|Event) failed to send.*/,
]

function filterLogEvents(event: LogsEvent, context: LogsEventDomainContext): boolean {
  try {
    if ((context as LogsEventDomainContext<'network'>).isAborted) return false
    if (event.isAborted) return false

    if (event.http) {
      // Do not log aborted network requests
      if (event.http.status_code === 0) return false

      // Do not log 3rd party errors
      const url = event.http.url
      if (HOSTS_TO_IGNORE.some((host) => url.includes(host))) return false
    }

    // Do not log error messages generated on the client that we know are not useful
    const message = event.message
    const errorMessage = event.error?.message || ''
    if (LOG_MESSAGES_TO_IGNORE.some((regex) => regex.test(message) || regex.test(errorMessage))) return false

    return true
  } catch (e) {
    // if something goes wrong, err on the side of logging the event.
    return true
  }
}

interface SendDatadogLogMessagePayload extends SendLogMessagePayload<DatadogLogger> {}

export function sendLogMessage({
  getDefaultContext,
  logger,
  level,
  message,
  context,
  error,
}: SendDatadogLogMessagePayload): void {
  const c = { ...getDefaultContext(), ...context }
  if (isAnError(error)) logger[level](message, c, error)
  else if (isAnError(context)) logger[level](message, getDefaultContext(), context)
  else {
    const err = getErrorFromContext(c)
    if (err) logger[level](message, c, err)
    else logger[level](message, c)
  }
}
