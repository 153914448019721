import React from 'react'
import { getCookieConsentProvider } from './utils'

export function CookieConsentScript() {
  const provider = getCookieConsentProvider()

  if (provider === 'osano') return <OsanoScripts />
  if (provider === 'cookie_yes') return <CookieYesScripts />
  if (provider === 'pie_eye') return <PieEyeScripts />

  return null
}

const OsanoScripts: React.FC = () => {
  return (
    <>
      {/* eslint-disable-next-line @next/next/no-sync-scripts */}
      <script
        id="shopify-consent-tracking-api-script"
        src="https://cdn.shopify.com/shopifycloud/consent-tracking-api/v0.1/consent-tracking-api.js"
      />

      {/* Consent Initialization: Inline Shopify Script */}
      <script
        id="shopify-custom-set-tracking-consent-script"
        dangerouslySetInnerHTML={{
          __html: /* javascript */ `
            const setTrackingConsent = window.Shopify.customerPrivacy.setTrackingConsent;
            window.Shopify.customerPrivacy.setTrackingConsent = (function(consentOptions, callback) {
              return setTrackingConsent({
                ...consentOptions,
                headlessStorefront: true,
                checkoutRootDomain: 'shop.angel.com',
                storefrontRootDomain: window.location.hostname,
                storefrontAccessToken: 'ba113b3c3998ae023db05e42a85a6ac8'
              }, (e) => {
                if (e) console.error('Error initializing shopify cookie consent', e)
              })
            }).bind(window.Shopify.customerPrivacy)
          `,
        }}
      />
      <style dangerouslySetInnerHTML={{ __html: `.osano-cm-widget {display: none;}` }} />

      {/* eslint-disable-next-line @next/next/no-sync-scripts */}
      <script
        id="prepare-osano-script"
        dangerouslySetInnerHTML={{
          __html: /* javascript */ `

            // get the osano script experiment cookie
            const osanoScriptExperimentCookie = getCookie('experiment_osano_cookie_consent');
            const osanoExperimentInstanceKey = processValue('${process.env.NEXT_PUBLIC_OSANO_EXPERIMENT_INSTANCE_KEY}');
            const osanoInstanceKey = processValue('${process.env.NEXT_PUBLIC_OSANO_INSTANCE_KEY}');
            
            if (osanoExperimentInstanceKey && osanoScriptExperimentCookie === 'variant') {
              addStyle({
                id: 'osano-script-style',
                css: /* css */ ".osano-cm-dialog {display: none;}",
              })
              addScript({
                id: 'osano-script',
                src: 'https://cmp.osano.com/16A4MgTIwm4CY2fAD/' + osanoExperimentInstanceKey + '/osano.js',
              })
            } else {
              addScript({
                id: 'osano-script',
                src: 'https://cmp.osano.com/16A4MgTIwm4CY2fAD/' + osanoInstanceKey + '/osano.js',
              })
            }


            const timer = setInterval(() => {
              if (window.Osano) {
                clearInterval(timer)
                addScript({
                  id: 'osano-shopify-ready-script',
                  type: 'text/javascript',
                  innerHTML: "try { window.Osano.cm.ready('shopify'); } catch (error) { console.error('Osano must be loaded before initializing the Shopify API.'); }"
                })
              }
            }, 100)
            
            function getCookies(options) {
              var _cookies = {};
              var documentCookies = document.cookie ? document.cookie.split("; ") : [];
              for (var i = 0, len = documentCookies.length; i < len; i++) {
                var cookieParts = documentCookies[i].split("=");
                var _cookie = cookieParts.slice(1).join("=");
                var name_1 = cookieParts[0];
                _cookies[name_1] = _cookie;
              }
              return _cookies;
            }
            function getCookie(key, options) {
              var _cookies = getCookies(options);
              var value = _cookies[key];
              if (value === undefined) return undefined;
              return processValue(decode(value));
            }
            function decode(str) {
              if (!str) return str;
              return str.replace(/(%[0-9A-Z]{2})+/g, decodeURIComponent);
            }
            function processValue(value) {
              if (value === "true") return true;
              if (value === "false") return false;
              if (value === "undefined") return undefined;
              if (value === "null") return null;
              return value;
            }
            function addScript({
              id,
              async = false,
              type = 'text/javascript',
              src,
              innerHTML,
            }) {
              const scriptElement = document.createElement('script')
              scriptElement.id = id
              scriptElement.async = async
              scriptElement.type = type
              if (src) scriptElement.src = src
              if (innerHTML) scriptElement.innerHTML = innerHTML
              document.head.appendChild(scriptElement)
            }
            function addStyle({
              id,
              css,
            }) {
              const styleElement = document.createElement('style')
              styleElement.id = id
              styleElement.innerHTML = css
              document.head.appendChild(styleElement)
            }
          `,
        }}
      />
      {/* eslint-disable-next-line @next/next/no-sync-scripts */}
      {/* <script
        id="osano-script"
        src={`https://cmp.osano.com/16A4MgTIwm4CY2fAD/${process.env.NEXT_PUBLIC_OSANO_INSTANCE_KEY}/osano.js`}
      /> */}

      {/* Consent Initialization: Inline Osano Script */}
      {/* <script
        id="osano-shopify-ready-script"
        dangerouslySetInnerHTML={{
          __html: `
            try {
                window.Osano.cm.ready('shopify');
            } catch (error) {
                console.error('Osano must be loaded before initializing the Shopify API.');
            }
          `,
        }}
      /> */}
    </>
  )
}

const CookieYesScripts: React.FC = () => {
  return (
    <>
      <script
        id="cookieyes"
        async
        type="text/javascript"
        src="https://cdn-cookieyes.com/client_data/1740ef65150dc012619d569e/script.js"
      ></script>
      <style dangerouslySetInnerHTML={{ __html: `.cky-btn-revisit-wrapper {display: none;}` }} />
    </>
  )
}

const PieEyeScripts: React.FC = () => {
  return (
    <>
      {/* Cookie Consent Dialog Script */}
      <script
        id="pieyecookiejs"
        async
        src="https://cdn.cookie.pii.ai/1/release/1.5/main.js"
        config-url="https://cdn.cookie.pii.ai/scripts/1/ws/b880dddf-f59e-4428-b076-4f74b3dfd9ce/domain/a0932b31-34c9-450c-89c2-ba248d3cda4a/config-a0932b31-34c9-450c-89c2-ba248d3cda4a.json"
      ></script>

      {/* Do Not Sell Script */}
      <link rel="stylesheet" href="https://pieeyegpc.pii.ai/1/dsrp/d58d895f-5029-407d-b576-503f3b3aa7e9/gpc.css" />
      <script
        id="pieyedonotsell"
        async
        src="https://pieeyegpc.pii.ai/1/dsrp/d58d895f-5029-407d-b576-503f3b3aa7e9/gpc.min.js"
      ></script>
    </>
  )
}
