import { useCallback, useEffect, useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useRouter } from 'next/router'
import { AnalyticsProvider } from 'use-analytics'
import { ExperimentContextProvider } from '@/experimentation/ExperimentContextProvider'
import { growthbook } from '@/experimentation/client'
import { useCookieConsent } from '@/molecules/CookieManagement/hooks'
import { analytics, regionName } from '@/services/Analytics'
import { fullStoryIdentify, fullStoryStart } from '@/services/Analytics/FullStory'
import { useHasVPPAConsent } from '@/services/EllisIslandService/legalDocumentHooks'
import { useUser } from '@/services/UserService'
import { ReactFCC } from '@/types/react'
import { getItemOrFirstEntry } from '@/utils/array-utils'
import { getObjectFromSessionStorage, writeToSessionStorage } from '@/utils/session-storage'
import { getUserDisplayName } from '@/utils/users'

export { analytics } from '@/services/Analytics'

export const Analytics: ReactFCC = ({ children }) => {
  const { user } = useUser()
  const router = useRouter()
  const [hasLoaded, setHasLoaded] = useState(false)

  const region = useMemo(() => regionName(router?.query?.region as string), [router?.query?.region])

  const hasVPPAConsent = useHasVPPAConsent()
  const cookieConsent = useCookieConsent()

  useEffect(() => {
    const userId = user?.uuid || analytics?.user()?.userId
    const displayName = getUserDisplayName(user)
    const noVPPAConsent = 'no_vppa_consent'

    const fb_login_id = user?.socialAuth?.find((auth) => auth?.provider === 'facebook')?.socialUid || null
    const email = hasVPPAConsent ? user?.email : noVPPAConsent
    const userTraits = {
      email,
      name: hasVPPAConsent ? displayName : noVPPAConsent,
      nickname: null, // no longer exists on api
      picture: hasVPPAConsent ? user?.profile?.image : noVPPAConsent,
      region,
      fb_login_id: fb_login_id || null,
      hasVPPAConsent,
    }

    fullStoryIdentify(userId, { displayName, email })
    analytics.identify(userId, userTraits)

    if (userId) {
      window?.branch?.setIdentity(userId)
    }
  }, [user, hasVPPAConsent, region])

  const handleScreenChange = useCallback((url: string) => {
    const previous = analytics.getState().page?.last?.properties?.to
    analytics.page({
      from: previous || url,
      to: url,
      url,
    })
  }, [])

  useEffect(() => {
    if (!hasLoaded && router) {
      setHasLoaded(true)
      const initialPageLoadUrl = router.asPath
      if (isEmpty(getObjectFromSessionStorage('originalUtms')) && router?.query) {
        const originalUtms = {} as Record<string, string>
        for (const [key, value] of Object.entries(router.query)) {
          if (key.startsWith('utm_') && !!value) {
            originalUtms[key] = getItemOrFirstEntry(value)
          }
        }
        writeToSessionStorage('originalUtms', originalUtms)
      }

      handleScreenChange(initialPageLoadUrl)
    }
  }, [handleScreenChange, router, hasLoaded])

  useEffect(() => {
    if (cookieConsent?.analytics) fullStoryStart()
  }, [cookieConsent])

  const instance = useMemo(() => analytics, [])

  return (
    <>
      <ExperimentContextProvider growthbook={growthbook}>
        <AnalyticsProvider instance={instance}>{children}</AnalyticsProvider>
      </ExperimentContextProvider>
    </>
  )
}
