import React, { useContext } from 'react'
import { Transition } from '@headlessui/react'
import { getTransitionClasses } from '@/molecules/utils'
import { ToastMessage } from './ToastMessage'
import { ToastContext } from './ToastProvider'

export const Toaster = () => {
  const { canShowToast, closeToast, toast } = useContext(ToastContext)

  return (
    <div className="fixed z-[999999] mx-auto mt-16 w-full">
      <div className="mx-4 flex justify-center">
        <div className="pointer-events-none translate-y-[33%]">
          <Transition show={canShowToast} {...getTransitionClasses('toast')} className="pointer-events-auto">
            <ToastMessage
              shouldHideClose={toast?.shouldHideClose}
              icon={toast?.icon}
              button={toast?.button}
              message={toast?.message as string}
              onClose={closeToast}
              showInMillis={toast?.showInMillis}
              className={toast?.className}
            />
          </Transition>
        </div>
      </div>
    </div>
  )
}
