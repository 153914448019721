/* eslint-disable @typescript-eslint/no-explicit-any */
import type { DefaultContextFn, Logger, SendLogMessagePayload } from './shared'
import { createLogger, getConfiguredLogLevel, getLogLevelNumber, isAnError } from './shared'

export function getConsoleLogger(getDefaultContext: DefaultContextFn): Logger {
  return createLogger({ getDefaultContext, logger: console, sendLogMessage })
}

export interface SendConsoleLogMessagePayload extends SendLogMessagePayload<Console> {}

export function sendLogMessage({
  getDefaultContext,
  logger,
  level,
  message,
  context,
  error,
}: SendConsoleLogMessagePayload): void {
  const shouldLog = getLogLevelNumber(level) >= getLogLevelNumber(getConfiguredLogLevel())
  if (!shouldLog) return

  const c = { ...getDefaultContext(), ...context }
  if (error) logger[level](message, c, error)
  else if (isAnError(context)) logger[level](message, getDefaultContext(), context)
  else if (Object.keys(c).length) logger[level](message, c)
  else logger[level](message)
}
