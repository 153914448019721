import { isClient } from '../client'
import { cLogger } from './client-logger'
import { sLogger } from './server-logger'
import type { Logger } from './shared'

/**
 * A logger function that dynamically determines whether to use client-side or server-side logging.
 */
export function logger(): Logger {
  if (isClient()) return cLogger()
  else return sLogger()
}
