import { logger } from '../logging'
import {
  SessionStorageBooleanKey,
  SessionStorageFloatKey,
  SessionStorageIntegerKey,
  SessionStorageKey,
  SessionStorageObjectKey,
  SessionStorageStringKey,
} from './session-storage-keys'

export function getStringFromSessionStorage(key: SessionStorageStringKey): string | null
export function getStringFromSessionStorage(key: SessionStorageStringKey, defaultValue: string): string
export function getStringFromSessionStorage(key: SessionStorageStringKey, defaultValue?: string): string | null {
  const value = getFromSessionStorage(key)
  return value ? value : defaultValue ? defaultValue : null
}

export function getBooleanFromSessionStorage(key: SessionStorageBooleanKey): boolean | null
export function getBooleanFromSessionStorage(key: SessionStorageBooleanKey, defaultValue: boolean): boolean
export function getBooleanFromSessionStorage(key: SessionStorageBooleanKey, defaultValue?: boolean): boolean | null {
  const value = getFromSessionStorage(key)
  if (!value) return defaultValue ?? null

  if (/true/i.test(value)) return true
  if (/false/i.test(value)) return false

  reportParseError('boolean', key, value)
  return defaultValue ?? null
}

export function getIntegerFromSessionStorage(key: SessionStorageIntegerKey): number | null
export function getIntegerFromSessionStorage(key: SessionStorageIntegerKey, defaultValue: number): number
export function getIntegerFromSessionStorage(key: SessionStorageIntegerKey, defaultValue?: number): number | null {
  const value = getFromSessionStorage(key)
  if (!value) return defaultValue ?? null

  const parsed = Number.parseInt(value)
  if (isNaN(parsed)) {
    reportParseError('integer', key, value)
    return defaultValue ?? null
  }

  return parsed
}

export function getFloatFromSessionStorage(key: SessionStorageFloatKey): number | null
export function getFloatFromSessionStorage(key: SessionStorageFloatKey, defaultValue: number): number
export function getFloatFromSessionStorage(key: SessionStorageFloatKey, defaultValue?: number): number | null {
  const value = getFromSessionStorage(key)
  if (!value) return defaultValue ?? null

  const parsed = Number.parseFloat(value)
  if (isNaN(parsed)) {
    reportParseError('float', key, value)
    return defaultValue ?? null
  }

  return parsed
}

export function getObjectFromSessionStorage<T extends object>(key: SessionStorageObjectKey): T | null
export function getObjectFromSessionStorage<T extends object>(
  key: SessionStorageObjectKey,
  defaultValue: T | null,
): T | null
export function getObjectFromSessionStorage<T extends object>(
  key: SessionStorageObjectKey,
  defaultValue?: T | null,
): T | null {
  const value = getFromSessionStorage(key)
  if (!value) return defaultValue ?? null

  try {
    const parsed = JSON.parse(value)
    if (parsed && typeof parsed === 'object') return parsed
    else throw new Error()
  } catch (err) {
    if (value === 'undefined') {
      // Incorrect type checking and lack of input validation resulted in bad data getting written. Delete these bad entries.
      removeFromSessionStorage(key)
    } else {
      reportParseError('object', key, value)
    }
    return defaultValue ?? null
  }
}

export function writeToSessionStorage(key: SessionStorageStringKey, value: string): void
export function writeToSessionStorage(key: SessionStorageBooleanKey, value: boolean): void
export function writeToSessionStorage(key: SessionStorageIntegerKey, value: number): void
export function writeToSessionStorage(key: SessionStorageFloatKey, value: number): void
export function writeToSessionStorage<T extends object>(key: SessionStorageObjectKey, value: T): void
export function writeToSessionStorage<T extends boolean | number | object | string>(
  key: SessionStorageKey,
  value: T,
): void {
  if (typeof sessionStorage === 'undefined' || !sessionStorage) return

  if (value === undefined) {
    logger().error(`Attempted to write a value of "undefined" to sessionStorage. Operation failed.`, { key })
  } else if (value === null) {
    logger().error(`Attempted to write a value of "null" to sessionStorage. Operation failed.`, { key })
  } else {
    const strVal = typeof value === 'object' ? JSON.stringify(value) : value.toString()
    sessionStorage.setItem(key, strVal)
  }
}

export function removeFromSessionStorage(key: SessionStorageKey): void {
  if (typeof sessionStorage === 'undefined' || !sessionStorage) return
  sessionStorage.removeItem(key)
}

function getFromSessionStorage(key: SessionStorageKey): string | null {
  if (typeof sessionStorage === 'undefined' || !sessionStorage) return null
  return sessionStorage.getItem(key)
}

function reportParseError(type: string, key: SessionStorageKey, value: string): void {
  logger().error(`Failed to parse a value from sessionStorage.`, { type, key, value })
}
