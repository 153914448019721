import React, { FC, useMemo } from 'react'
import { Listbox } from '@headlessui/react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { LanguageIcon } from '@/atoms/Icons/LanguageIcon'
import { useLanguages } from '@/molecules/LanguageSelector'
import { LanguageHeader } from '@/molecules/LanguageSelector/LanguageHeader'
import { LanguageOption } from '@/molecules/LanguageSelector/LanguageOption'
import { getLocaleFromPath } from '@/utils/LocaleUtil/LocaleUtil'
import { useTranslate } from '@/utils/translate/translate-client'

interface LanguageMenuProps {
  className?: string
  isOpen?: boolean
  isDarkMode?: boolean
  onClose: () => void
  onOpen: () => void
  showDefaultMobileNav?: boolean
}

export const LanguageMenu: FC<LanguageMenuProps> = ({
  className,
  isOpen,
  isDarkMode,
  onClose,
  onOpen,
  showDefaultMobileNav,
}) => {
  const { t } = useTranslate('common')
  const { asPath } = useRouter()
  const { betaLanguages, publicLanguages, saveLanguage, languagesEnabled, betaLanguagesEnabled } = useLanguages()

  const locale = useMemo(() => {
    return getLocaleFromPath(asPath)
  }, [asPath])

  const backgroundClasses = isDarkMode ? 'bg-gray-900' : 'bg-white'
  const dividerClasses = isDarkMode ? 'border-white/10' : 'border-gray-800/10'
  const closeIconColor = isDarkMode ? 'white' : 'gray-800'
  const languageIconColor = isDarkMode ? 'white' : 'gray-950'

  const handleOnChange = (language: string) => {
    onClose()
    saveLanguage(language)
  }

  if (!languagesEnabled) {
    return null
  }

  return (
    <Listbox
      className={classNames(
        'relative flex flex-row items-center',
        className,
        showDefaultMobileNav && 'hidden md:block',
      )}
      as="div"
      onChange={handleOnChange}
      value={locale}
      aria-label="select language"
    >
      <Listbox.Button aria-label={t('languageMenu', 'Language Menu')} onClick={onOpen}>
        <LanguageIcon color={languageIconColor} />
      </Listbox.Button>
      {isOpen && (
        <>
          <div className="absolute -right-0 top-10 h-[2px] w-7 bg-oxide-700"></div>
          <div
            className={classNames(
              'absolute top-10 -right-0 min-w-[200px] max-h-[85vh] p-5 mt-4 rounded-lg overflow-y-auto',
              backgroundClasses,
            )}
            style={{ boxShadow: '0px -4px 16px -1px rgba(0, 0, 0, 0.05), 0px 8px 16px -1px rgba(0, 0, 0, 0.12)' }}
          >
            <Listbox.Options static>
              <div className="mb-4 flex items-center justify-between">
                <LanguageHeader isDarkMode={isDarkMode} value={t('languages', 'Languages')} />
                <div className="cursor-pointer" onClick={onClose}>
                  <CloseIcon
                    aria-label={t('closeLanguageMenu', 'Close Language Menu')}
                    size={18}
                    color={closeIconColor}
                  />
                </div>
              </div>
              {publicLanguages.map((language) => (
                <Listbox.Option key={language} value={language}>
                  {({ selected }) => <LanguageOption isDarkMode={isDarkMode} language={language} selected={selected} />}
                </Listbox.Option>
              ))}
              {betaLanguagesEnabled && (
                <>
                  <div
                    className={classNames(
                      'border border-t-0 border-l-0 border-r-0 border-b-1 mt-3 mb-5',
                      dividerClasses,
                    )}
                  />
                  <div className="mb-4">
                    <LanguageHeader isDarkMode={isDarkMode} value={t('betaLanguages', 'Beta Languages')} />
                  </div>
                  {betaLanguagesEnabled &&
                    betaLanguages.map((language) => (
                      <Listbox.Option key={language} value={language}>
                        {({ selected }) => (
                          <LanguageOption isDarkMode={isDarkMode} language={language} selected={selected} />
                        )}
                      </Listbox.Option>
                    ))}
                </>
              )}
            </Listbox.Options>
          </div>
        </>
      )}
    </Listbox>
  )
}
