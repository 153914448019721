/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable no-console */
import Bugsnag, { type NotifiableError } from '@bugsnag/js'
import { isDevelopmentEnvironment, isProductionEnvironment } from '../environment-utils'

export function reportErrorToBugsnag(err: NotifiableError): void {
  if (isProductionEnvironment()) {
    Bugsnag.notify(err)
  } else {
    console.error(err)
    if (isDevelopmentEnvironment()) {
      if (typeof err === 'string') throw new Error(err)
      else if (typeof err === 'object') throw err
    }
  }
}
