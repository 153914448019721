import { getCookie } from 'cookies-next'
import { EVERFLOW_TRANSACTION_ID_COOKIE } from '@/constants/cookies'
import { analytics } from '../analytics'
import { namedLogger } from '../utils'
import { type AnalyticsPlugin } from './types'

const EF_TRANSACTION_ID_LOCAL_STORAGE = '_ef_transaction_id' as const
const EF_TRANSACTION_ID_QUERY_PARAM = '_ef_transaction_id' as const

const logger = namedLogger('everflow Analytics Plugin')

type AnalyticsEventParams = {
  payload: {
    properties: {
      _ef_transaction_id?: string
    }
  }
}

function getEverflowTransactionId() {
  logger.debug('Everflow getEverflowTransactionId')
  if (typeof window === 'undefined') return
  try {
    const { searchParams } = new URL(window.location.href, window.location.origin)
    const paramsEfTransactionId = searchParams.get(EF_TRANSACTION_ID_QUERY_PARAM)
    if (paramsEfTransactionId) {
      localStorage.setItem(EF_TRANSACTION_ID_LOCAL_STORAGE, paramsEfTransactionId)
      return paramsEfTransactionId
    }

    const cookieEfTransactionId = getCookie(EVERFLOW_TRANSACTION_ID_COOKIE)
    if (typeof cookieEfTransactionId === 'string' && cookieEfTransactionId) {
      localStorage.setItem(EF_TRANSACTION_ID_LOCAL_STORAGE, cookieEfTransactionId)
      return cookieEfTransactionId
    }

    const localStorageEfTransactionId = localStorage.getItem(EF_TRANSACTION_ID_LOCAL_STORAGE)
    if (localStorageEfTransactionId) {
      return localStorageEfTransactionId
    }

    return analytics?.user()?.traits?._ef_transaction_id || undefined
  } catch (error) {
    return
  }
}

function attachEverflow(params: AnalyticsEventParams) {
  let _ef_transaction_id = params.payload.properties._ef_transaction_id

  if (!_ef_transaction_id) {
    _ef_transaction_id = getEverflowTransactionId()
  }

  logger.debug('_ef_transaction_id', { _ef_transaction_id })
  if (_ef_transaction_id) {
    params.payload.properties._ef_transaction_id = _ef_transaction_id
  }
}

export function everflowAnalyticsPlugin() {
  const plugin: AnalyticsPlugin = {
    name: 'everflow',

    page() {
      try {
        const _ef_transaction_id = getEverflowTransactionId()
        logger.debug('page', { _ef_transaction_id })
        if (_ef_transaction_id && _ef_transaction_id !== analytics?.user()?.traits?._ef_transaction_id) {
          analytics.identify(analytics?.user()?.userId, { _ef_transaction_id })
        }
      } catch (error) {
        logger.error('page error', undefined, error)
      }
    },

    pageStart(params: AnalyticsEventParams) {
      try {
        logger.debug('pageStart', params)
        attachEverflow(params)
      } catch (error) {
        logger.error('pageStart error', undefined, error)
      }
    },

    trackStart(params: AnalyticsEventParams) {
      try {
        logger.debug('trackStart', params)
        attachEverflow(params)
      } catch (error) {
        logger.error('trackStart error', undefined, error)
      }
    },

    loaded() {
      logger.debug('loaded')
      return true
    },
  }

  return plugin
}
