// Direct imports on this page are needed for script to run.  Otherwise MODULE NOT FOUND error will be thrown.
import { Maybe } from 'graphql/jsutils/Maybe'
import kebabCase from 'lodash/kebabCase'
import qs from 'qs'
import locales from '../../constants/locales'
import { projectTypes } from '../../constants/projectTypes'
import { slugs } from '../../constants/slugs'
import { Episode, ProjectType } from '../../services/ProjectsService'
import { Media } from '../../utils/EpisodeUtil/Media'

interface ProjectEpisodeTitleComponents {
  projectName: string
  projectType?: string
  seasonNumber: number
  episodeNumber: number
}

export const buildProjectEpisodeTitle = ({
  projectName,
  projectType,
  seasonNumber,
  episodeNumber,
}: ProjectEpisodeTitleComponents): string => {
  if (projectType === 'series') {
    return `S${seasonNumber}:E${episodeNumber} ${projectName}`
  } else {
    return projectName
  }
}

interface EpisodeTitleComponents {
  title: string
  projectType?: ProjectType
  seasonNumber: number
  episodeNumber: number
}

export const buildEpisodeTitle = ({
  title,
  projectType,
  seasonNumber,
  episodeNumber,
}: EpisodeTitleComponents): string => {
  if (projectType === 'series') {
    return `S${seasonNumber} E${episodeNumber} - ${title}`
  } else {
    return title
  }
}

interface AbbreviatedEpisodeReferenceComponents {
  name: string
  seasonNumber: number
  episodeNumber: number
  projectType?: ProjectType
}

export const buildAbbreviatedEpisodeReference = ({
  name,
  episodeNumber,
  seasonNumber,
  projectType,
}: AbbreviatedEpisodeReferenceComponents): string => {
  if (projectType === 'series') {
    return `S${seasonNumber} E${episodeNumber}`
  } else {
    return name
  }
}

interface EpisodeDecoratorComponents {
  seasonNumber?: number
  episodeNumber?: number
  subtitle?: string
}

interface EpisodeLinkComponents extends EpisodeDecoratorComponents {
  projectSlug: string
  guid: string
}

/**
 * position and smpte are exclusive only one can be used.
 * This function prefers position over smpte if both are provided.
 */
interface EpisodeLinkOptions {
  // alternative to project slug
  slug?: string
  // seek head position in seconds
  position?: number
  // seek head position in smpte
  smpte?: string
  disableDecoration?: boolean
}

function isEpisodeLinkOptions(options: unknown): options is EpisodeLinkOptions {
  return typeof options === 'object' && Boolean(options)
}

export function buildDecorativeEpisodePath(
  { seasonNumber, episodeNumber, subtitle }: EpisodeDecoratorComponents,
  options?: string | EpisodeLinkOptions,
) {
  const disableDecoration = typeof options !== 'string' ? options?.disableDecoration : false
  if (disableDecoration) return ''

  const seasonNumberParam = typeof seasonNumber === 'number' && seasonNumber >= 0 ? `/season-${seasonNumber}` : ''
  const episodeNumberParam = typeof episodeNumber === 'number' && episodeNumber >= 0 ? `/episode-${episodeNumber}` : ''
  const subTitleParam = subtitle ? `/${kebabCase(subtitle)}` : ''

  const decorativeEpisodePath =
    seasonNumberParam && episodeNumberParam && subTitleParam
      ? `${seasonNumberParam}${episodeNumberParam}${subTitleParam}`
      : ''
  return decorativeEpisodePath
}

export function buildLinkToWatchable(
  projectType: ProjectType,
  episode: EpisodeLinkComponents,
  locale: string,
  options?: EpisodeLinkOptions,
): string {
  return projectType === projectTypes.series && episode.projectSlug !== slugs.dryBar
    ? buildLinkToEpisode(
        {
          projectSlug: episode.projectSlug,
          episodeNumber: episode.episodeNumber,
          subtitle: episode.subtitle,
          seasonNumber: episode.seasonNumber,
          guid: episode.guid,
        },
        { ...options, disableDecoration: locale !== locales.defaultLocale },
      )
    : `/watch/${episode.projectSlug}/episode/${episode.guid}${qs.stringify(
        {
          position: options?.position ? Math.trunc(options.position) : null,
          smpte: !options?.position && options?.smpte ? options.smpte : null,
        },
        {
          skipNulls: true,
          addQueryPrefix: true,
        },
      )}`
}

export function buildLinkToEpisode(
  { projectSlug, guid, seasonNumber, episodeNumber, subtitle }: EpisodeLinkComponents,
  options?: string | EpisodeLinkOptions,
): string {
  const decorativeEpisodePath = buildDecorativeEpisodePath({ seasonNumber, episodeNumber, subtitle }, options)

  let projectSlugParam = projectSlug
  let position
  let smpte

  if (typeof options === 'string') {
    projectSlugParam = projectSlugParam ?? options
  } else if (isEpisodeLinkOptions(options)) {
    projectSlugParam = projectSlugParam ?? options.slug
    position = options.position ? Math.trunc(options.position) : null
    smpte = !position && options.smpte ? options.smpte : null
  }

  return `/watch/${projectSlugParam}/episode/${guid}${decorativeEpisodePath}${qs.stringify(
    { position, smpte },
    {
      skipNulls: true,
      addQueryPrefix: true,
    },
  )}`
}

export function episodesToMedias(episodes: Episode[] = [], options: EpisodeToMediaOptions = {}): Media[] {
  return episodes.map((episode) => {
    return episodeToMedia(episode, options)
  })
}

interface EpisodeToMediaOptions {
  displayDescription?: boolean
  hasEarlyAccess?: boolean
  projectName?: string
  projectType?: ProjectType
  trailerText?: string
  locale?: string
  position?: number
  progress?: number
}

const defaultEpisodeToMediaOptions: EpisodeToMediaOptions = {
  displayDescription: true,
  hasEarlyAccess: false,
  locale: 'en',
  projectType: 'series',
}

export function episodeToMedia(episode: Episode, options: EpisodeToMediaOptions = defaultEpisodeToMediaOptions): Media {
  const locale = options.locale ?? locales.defaultLocale
  const episodeTitle =
    episode.isTrailer && episode.name.toLowerCase().includes('trailer') ? options.trailerText : episode.name
  const episodeWatchPosition = episode?.watchPosition?.position
  const episodeDuration = episode?.source?.duration
  const progress = episodeWatchPosition && episodeDuration ? (episodeWatchPosition / episodeDuration) * 100 : 0

  return {
    description: episode.description,
    earlyAccessDate: episode.earlyAccessDate,
    episodeNumber: episode.episodeNumber,
    episodeSlug: episode.slug,
    guid: episode.guid,
    href: buildLinkToWatchable(options?.projectType ?? 'series', episode, locale, { position: options.position }),
    poster: episode.posterLandscapeCloudinaryPath ?? episode.posterCloudinaryPath,
    projectSlug: episode.projectSlug,
    publiclyAvailableDate: episode.publiclyAvailableDate,
    seasonNumber: episode.seasonNumber,
    subtitle: episode.subtitle,
    title: episodeTitle,
    unavailableReason: episode.unavailableReason,
    progress,
    ...options,
  }
}

export function isAPreview(episode: Maybe<Pick<Episode, 'name'>>): boolean {
  return Boolean(episode?.name?.toLowerCase().includes('preview'))
}

export function isASneakPeek(episode: Maybe<Pick<Episode, 'name' | 'slug'>>): boolean {
  return Boolean(
    episode?.name?.toLowerCase()?.includes('sneak peek') ||
      episode?.name?.toLowerCase()?.includes('sneek peak') ||
      episode?.slug?.includes('sneak-peek') ||
      episode?.slug?.includes('the-first-'),
  )
}

export function isATorch(episode: Maybe<Pick<Episode, 'subtitle'>>): boolean {
  return Boolean(episode?.subtitle?.toLowerCase()?.includes('torch'))
}

export function isATeaser(episode: Maybe<Pick<Episode, 'name' | 'subtitle'>>): boolean {
  return Boolean(
    episode?.name?.toLowerCase()?.includes('teaser') || episode?.subtitle?.toLowerCase()?.includes('teaser'),
  )
}

export function isAPitch(episode: Maybe<Pick<Episode, 'name'>>): boolean {
  return Boolean(episode?.name?.toLowerCase()?.includes('pitch'))
}

export function isARecap(episode: Maybe<Pick<Episode, 'name'>>): boolean {
  return Boolean(episode?.name?.toLowerCase()?.includes('recap'))
}

export function isALivestream(episode: Maybe<Pick<Episode, 'name'>>): boolean {
  return Boolean(episode?.name?.toLowerCase()?.includes('livestream'))
}

interface DefaultCtaProps {
  ctaText: string
  currentEpisode?: Episode
  locale: string
  slug: string
  theaterDescription: string
}

type ShowHeroCta = {
  ctaPath?: string
  ctaText: string
  descriptionTitle?: string
  duration?: number
  loading: boolean
  position?: number
  showDescription: string
}

interface ContinueWatchingProps {
  currentEpisode: Episode
  locale: string
  projectType: ProjectType
  resume: string
  slug: string
}

export function getTrailerCta({
  ctaText,
  currentEpisode,
  locale,
  slug,
  theaterDescription,
}: DefaultCtaProps): ShowHeroCta {
  return {
    loading: false,
    showDescription: theaterDescription,
    ctaPath: currentEpisode
      ? buildLinkToEpisode(currentEpisode as Episode, { slug, disableDecoration: locale !== locales.defaultLocale })
      : undefined,
    ctaText,
  }
}

export function getContinueWatchingCta({
  currentEpisode,
  locale,
  projectType,
  resume,
  slug,
}: ContinueWatchingProps): ShowHeroCta {
  return {
    position: currentEpisode?.watchPosition?.position,
    duration: currentEpisode?.source?.duration,
    loading: false,
    showDescription: currentEpisode?.description,
    descriptionTitle:
      currentEpisode.projectSlug === slugs.dryBar
        ? currentEpisode?.subtitle
        : buildEpisodeTitle({
            title: currentEpisode?.subtitle,
            episodeNumber: currentEpisode?.episodeNumber,
            seasonNumber: currentEpisode?.seasonNumber,
            projectType,
          }),
    ctaPath: currentEpisode
      ? buildLinkToEpisode(currentEpisode as Episode, { slug, disableDecoration: locale !== locales.defaultLocale })
      : undefined,
    ctaText: `${resume} ${buildAbbreviatedEpisodeReference({
      name: currentEpisode?.projectSlug === slugs.dryBar ? currentEpisode?.subtitle : currentEpisode?.name,
      seasonNumber: currentEpisode?.seasonNumber,
      episodeNumber: currentEpisode?.episodeNumber,
    })}`,
  }
}

export function getDefaultCta({
  ctaText,
  currentEpisode,
  locale,
  slug,
  theaterDescription,
}: DefaultCtaProps): ShowHeroCta {
  return {
    loading: false,
    showDescription: theaterDescription,
    ctaPath: currentEpisode
      ? buildLinkToEpisode(currentEpisode as Episode, { slug, disableDecoration: locale !== locales.defaultLocale })
      : undefined,
    ctaText,
  }
}

export function getContinueWatchingCtaNew({
  currentEpisode,
  locale,
  projectType,
  resume,
  slug,
}: ContinueWatchingProps): ShowHeroCta {
  return {
    position: currentEpisode?.watchPosition?.position,
    duration: currentEpisode?.source?.duration,
    loading: false,
    showDescription: currentEpisode?.description,
    descriptionTitle:
      currentEpisode.projectSlug === slugs.dryBar
        ? currentEpisode?.subtitle
        : buildEpisodeTitle({
            title: currentEpisode?.subtitle,
            episodeNumber: currentEpisode?.episodeNumber,
            seasonNumber: currentEpisode?.seasonNumber,
            projectType,
          }),
    ctaPath: currentEpisode
      ? buildLinkToEpisode(currentEpisode as Episode, { slug, disableDecoration: locale !== locales.defaultLocale })
      : undefined,
    ctaText: `${resume} ${buildAbbreviatedEpisodeReference({
      name: currentEpisode?.projectSlug === slugs.dryBar ? currentEpisode?.subtitle : currentEpisode?.name,
      seasonNumber: currentEpisode?.seasonNumber,
      episodeNumber: currentEpisode?.episodeNumber,
      projectType: projectType,
    })}`,
  }
}
