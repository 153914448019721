import React, { FC, MouseEventHandler, ReactNode } from 'react'
import classNames from 'classnames'
import { ExternalLink } from '@/atoms/ExternalLink'
import { InternalLink } from '@/atoms/InternalLink'
import { TitleXS } from '@/atoms/Text'
import { useThemeContext } from '@/contexts/ThemeContext'

interface IconLinkProps {
  href: string
  children: ReactNode
  linkText: string
  handleClick?: MouseEventHandler<HTMLAnchorElement>
  isExternal?: boolean
}

interface LinkContentProps {
  linkText: string
  children: ReactNode
  isDarkMode: boolean
}

const LinkContent: FC<LinkContentProps> = ({ children, linkText, isDarkMode }) => (
  <div
    className={classNames(
      isDarkMode ? 'hover:bg-white/10' : 'hover:bg-core-gray-950/[0.08]',
      'flex flex-row items-center space-x-2 p-2 hover:rounded-md md:px-4 py-3',
    )}
  >
    <div className="w-fit">{children}</div>
    <TitleXS className={isDarkMode ? 'text-white ' : 'text-gray-950 '} weight="normal">
      {linkText}
    </TitleXS>
  </div>
)

export const IconLink: FC<IconLinkProps> = ({ href, children, linkText, handleClick, isExternal }) => {
  const { isDarkMode } = useThemeContext()

  if (isExternal)
    return (
      <ExternalLink href={href} onClick={handleClick}>
        <LinkContent linkText={linkText} isDarkMode={isDarkMode}>
          {children}
        </LinkContent>
      </ExternalLink>
    )

  return (
    <InternalLink href={href} onClick={handleClick}>
      <LinkContent linkText={linkText} isDarkMode={isDarkMode}>
        {children}
      </LinkContent>
    </InternalLink>
  )
}
