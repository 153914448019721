import React, { MouseEventHandler, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { When } from 'react-if'
import { GuildRedeemTicketsCard } from 'src/molecules/GuildRedeemTicketsCard'
import { ButtonVariant, LinkButton } from '@/atoms/Button'
import { AccountCircleIcon } from '@/atoms/Icons/AccountCircleIcon'
import { ArrowLeftIcon } from '@/atoms/Icons/ArrowLeftIcon'
import { ChatBubbleOutline } from '@/atoms/Icons/ChatBubbleOutline'
import { CircleQuestionIcon } from '@/atoms/Icons/CircleQuestionIcon'
import { CreditCardIcon } from '@/atoms/Icons/CreditCardIcon'
import { GearIcon } from '@/atoms/Icons/GearIcon'
import { LogoutIcon } from '@/atoms/Icons/LogoutIcon'
import { SceneIcon } from '@/atoms/Icons/SceneIcon'
import { TicketIcon } from '@/atoms/Icons/TicketIcon'
import TriangleArrowRight from '@/atoms/Icons/TriangleArrowRight'
import { CaptionSM, TitleMD } from '@/atoms/Text'
import { paths } from '@/constants/paths'
import { useThemeContext } from '@/contexts/ThemeContext'
import { Avatar } from '@/molecules/Avatar'
import { useGuildMember } from '@/services/GuildUserService'
import { useUser } from '@/services/UserService'
import { useSafeTrack } from '@/utils/analytics'
import { useLoginUrl, useLogoutUrl } from '@/utils/auth-utils'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { IconLink } from '../IconLink'
import { LanguageAccordion } from '../LanguageAccordion'
import { UserMenuHeader } from './UserMenuHeader'

interface Props {
  loginButtonVariant: ButtonVariant
  onClick?: MouseEventHandler
}

export const UserMenu: React.FC<Props> = ({ loginButtonVariant, onClick }) => {
  const { isLoggedIn, loading } = useUser()
  const { guildPermissions } = useGuildMember()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { loginUrl, trackLoginStarted } = useLoginUrl()
  const { logoutUrl, trackLogoutStarted } = useLogoutUrl()
  const { t } = useTranslate('common')
  const { isDarkMode } = useThemeContext()
  const track = useSafeTrack()
  const ref = useRef<HTMLDivElement | null>(null)
  const shareGuildCodesModalRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (shareGuildCodesModalRef?.current?.contains(event.target as Node)) return

      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsMenuOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleTrackingClick = () => {
    track('Clicked User Menu Link')
  }

  const handleClickAwayFromMenu: MouseEventHandler = (e) => {
    e.preventDefault()
    setIsMenuOpen((x) => !x)
  }

  const handleItemClick: MouseEventHandler = (e) => {
    handleTrackingClick()
    setIsMenuOpen((x) => !x)
    if (onClick) onClick(e)
  }

  if (loading) {
    return <div className="w-8" />
  }

  const avatarClasses = classNames(
    isDarkMode ? 'border-black ring-white' : 'border-white ring-black',
    'overflow-hidden rounded-full border-2 bg-cover ring-2 w-fit',
  )

  if (!isLoggedIn) {
    return (
      <div className="flex items-center">
        <LinkButton
          href={loginUrl}
          variant={loginButtonVariant}
          outline
          className="box-border h-8 rounded-md backdrop-blur-sm"
          onClick={() => trackLoginStarted('top-nav')}
        >
          <CaptionSM className="whitespace-nowrap">
            <Translate t={t} i18nKey="logIn">
              Log In
            </Translate>
          </CaptionSM>
        </LinkButton>
      </div>
    )
  }

  return (
    <div ref={ref}>
      <div className="md:mr-6">
        <When condition={isMenuOpen}>
          <div
            className="absolute left-0 top-0 z-[9998] h-screen max-h-full w-screen max-w-full bg-transparent"
            onClick={handleClickAwayFromMenu}
          ></div>
        </When>
        <div className="md:relative">
          <div className={classNames(avatarClasses, 'ring-0')}>
            <Avatar onClick={handleItemClick} />
          </div>

          <When condition={isMenuOpen}>
            <div
              className={classNames(
                isDarkMode ? 'bg-black text-white' : 'bg-white text-black',
                'absolute right-0 top-0 z-[9999] h-screen w-full p-4 shadow-lg md:-right-[15px] md:top-[50px] md:h-fit md:w-[390px] md:rounded-lg md:pt-0',
              )}
            >
              <TriangleArrowRight
                color={isDarkMode ? 'black' : 'white'}
                className="absolute -top-[11px] right-6 origin-left -rotate-90"
              />
              <div onClick={handleItemClick} className="mb-4 md:hidden">
                <ArrowLeftIcon color={isDarkMode ? 'white' : 'black'} size={20} />
              </div>
              <section>
                <UserMenuHeader handleItemClick={handleItemClick} />

                <div className="-mx-2 md:mx-0">
                  <IconLink
                    href={paths.account.myImpact}
                    handleClick={handleItemClick}
                    linkText={t('myAccount', 'My Account')}
                  >
                    <AccountCircleIcon size={24} color1={isDarkMode ? 'black' : 'white'} color2="gray-600" />
                  </IconLink>
                  <IconLink
                    href={paths.account.index}
                    handleClick={handleItemClick}
                    linkText={t('membershipAndPayments', 'Membership & Payments')}
                  >
                    <CreditCardIcon size={24} color="gray-600" />
                  </IconLink>
                  <IconLink
                    href={paths.account.myTickets}
                    handleClick={handleItemClick}
                    linkText={t('myTickets', 'My Tickets')}
                  >
                    <TicketIcon size={24} color="gray-600" />
                  </IconLink>
                </div>
                {guildPermissions.hasFreeTickets && (
                  <GuildRedeemTicketsCard modalRef={shareGuildCodesModalRef} className="my-2" isDismissable />
                )}
                <div className="-mx-2 md:mx-0">
                  <IconLink
                    href={paths.account.collectibles}
                    handleClick={handleItemClick}
                    linkText={t('collectibles', 'Collectibles')}
                  >
                    <SceneIcon size={24} color1={isDarkMode ? 'black' : 'white'} color2="gray-600" />
                  </IconLink>
                  <IconLink
                    href={paths.account.settings}
                    handleClick={handleItemClick}
                    linkText={t('settings', 'Settings')}
                  >
                    <GearIcon size={24} color="gray-600" />
                  </IconLink>
                </div>
              </section>
              <section className="-mx-2 my-8 md:mx-0">
                <TitleMD weight="semibold" className="mx-2 mb-4">
                  {t('more', 'More')}
                </TitleMD>
                <IconLink
                  href={paths.external.zendesk.support}
                  handleClick={handleItemClick}
                  linkText={t('help', 'Help')}
                  isExternal={true}
                >
                  <CircleQuestionIcon size={24} color="gray-600" />
                </IconLink>
                <IconLink
                  href={paths.external.zendesk.contactUs}
                  handleClick={handleItemClick}
                  linkText={t('giveFeedback', 'Give Feedback')}
                  isExternal={true}
                >
                  <ChatBubbleOutline size={24} color="gray-600" />
                </IconLink>
                <LanguageAccordion onClose={() => setIsMenuOpen(!isMenuOpen)} />
              </section>

              <section className="-mx-2 mt-8 md:mx-0">
                <IconLink
                  href={logoutUrl}
                  linkText={t('logoutV2', 'Logout')}
                  handleClick={(e) => {
                    trackLogoutStarted('top-nav')
                    handleItemClick(e)
                  }}
                >
                  <LogoutIcon color="gray-600" />
                </IconLink>
              </section>
            </div>
          </When>
        </div>
      </div>
    </div>
  )
}
