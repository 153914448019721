import { IncomingMessage } from 'http'

export type QueryParams = Record<string, string | string[] | undefined>

export type ServerRequest = IncomingMessage & {
  cookies: Partial<{
    [key: string]: string
  }>
}

export type NonNullableFields<T> = {
  [P in keyof T]-?: NonNullable<T[P]>
}

export type Maybe<T> = T | null | undefined

/**
 * Useful alternative to .filter(Boolean) that is type safe
 */
export function isDefined<T>(value: T | null | undefined): value is NonNullable<T> {
  return value !== null && value !== undefined
}

export function isString(value: unknown): value is string {
  return typeof value === 'string'
}

export type Result<T, E = Error> = { ok: true; value: T | undefined } | { ok: false; error: E }

/**
 * DeepNonNullable is a utility type that makes all properties of an object non-nullable,
 * including nested properties up to N depth. For example,
 *
 * export type SimpleTicketItem = DeepNonNullable<GetSimpleTicketListQuery['reservations'], 3>['edges'][number]['node']
 *
 * The above type will have it's nullish values removed 3 layers deep so that we can safely access the node property.
 */

// Create a tuple type of specified length
type TupleOf<T, N extends number, R extends T[] = []> = R['length'] extends N ? R : TupleOf<T, N, [...R, T]>
type DecrementDepth<N extends number> = TupleOf<unknown, N> extends [...infer Rest, unknown] ? Rest['length'] : 0

export type DeepNonNullable<T, D extends number> = D extends 0
  ? T
  : T extends (infer U)[]
  ? DeepNonNullable<U, DecrementDepth<D>>[]
  : T extends object
  ? { [K in keyof T]-?: DeepNonNullable<T[K], DecrementDepth<D>> }
  : NonNullable<T>
