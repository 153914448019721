import dynamic, { DynamicOptions } from 'next/dynamic'
import { logger } from '@/utils/logging'
import { ExperimentVariantPickerProps } from './ExperimentVariantPicker'

export const ExperimentVariantPicker = dynamic(
  import('./ExperimentVariantPicker')
    .then((mod) => mod.ExperimentVariantPicker)
    .catch((err) =>
      logger().error(`Failed to load the ExperimentVariantPicker!`, err),
    ) as DynamicOptions<ExperimentVariantPickerProps>,
  { ssr: false },
)
