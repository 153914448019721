// Direct imports on this page are needed for script to run.  Otherwise MODULE NOT FOUND error will be thrown.
import { isAxiosError } from 'axios'
import { AUTH_JWT_COOKIE, AUTH_SESSION_COOKIE, AUTH_SESSION_COOKIE_0 } from '../../constants/cookies'
import { isDevelopmentEnvironment, isTestEnvironment } from '../environment-utils'
import { formatAxiosError } from './formatAxiosError'
import type { DefaultContextFn, Logger, SendLogMessagePayload } from './shared'
import { createLogger, getConfiguredLogLevel, getLogLevelNumber, isAnError } from './shared'

/* eslint-disable @typescript-eslint/no-explicit-any */
export function getConsoleJsonLogger(getDefaultContext: DefaultContextFn): Logger {
  return createLogger({ getDefaultContext, logger: console, sendLogMessage })
}

export interface SendConsoleJsonLogMessagePayload extends SendLogMessagePayload<Console> {}

export function sendLogMessage({
  getDefaultContext,
  logger,
  level,
  message,
  context,
  error,
}: SendConsoleJsonLogMessagePayload): void {
  const shouldLog = getLogLevelNumber(level) >= getLogLevelNumber(getConfiguredLogLevel())
  if (!shouldLog) return

  let c: any = { level, message, ...getDefaultContext() }

  if (isAnError(context)) c.error = context
  else if (context) c = { ...c, ...context }
  if (error) c.error = error

  logger[level](stringify(sanitize(errorFormatter(c))))
}

function stringify(obj: any): string {
  if (isDevelopmentEnvironment() && !isTestEnvironment()) {
    return JSON.stringify(obj, null, 2)
  } else {
    return JSON.stringify(obj)
  }
}

export function errorFormatter(c: any): any {
  if (isAnError(c.error)) {
    c.error = transformIfError(c.error)
  }
  if (isAnError(c.err)) {
    const temp = transformIfError(c.err)
    if (c.error) c.err = temp
    else {
      c.error = temp
      delete c.err
    }
  }
  if (isAnError(c.e)) {
    const temp = transformIfError(c.e)
    if (c.error) c.e = temp
    else {
      c.error = temp
      delete c.e
    }
  }
  return c
}

export function transformIfError(value: unknown): unknown {
  if (isAxiosError(value)) return formatAxiosError(value)
  else if (value instanceof Error) return { name: value.name, message: value.message, stack: value.stack }
  else return value
}

const MAX_DEPTH = 30
const FIELDS_TO_REDACT = [
  'authorization',
  'password',
  'newPassword',
  'cookie',
  AUTH_JWT_COOKIE,
  AUTH_SESSION_COOKIE,
  AUTH_SESSION_COOKIE_0,
].map((f) => f.toLowerCase())

// eslint-disable-next-line sonarjs/cognitive-complexity
export function sanitize(obj: any, hash = new WeakMap(), depth = 0): any {
  if (hash.has(obj)) return '[RECURSIVE_REFERENCE]: ' + (Array.isArray(obj) ? `Array[${obj.length}]` : obj.toString())
  if (depth > MAX_DEPTH) return '[MAX_DEPTH_REACHED]'

  if (Array.isArray(obj)) {
    const copy = new Array(obj.length)
    hash.set(obj, copy)
    obj.forEach((item, idx) => {
      copy[idx] = sanitize(item, hash, depth + 1)
    })
    return copy
  } else if (typeof obj === 'object' && obj !== null) {
    const copy: any = {}
    hash.set(obj, copy)
    const keys = Object.keys(obj)
    for (const key of keys) {
      const value = obj[key]
      const keyLower = key.toLowerCase()
      if (FIELDS_TO_REDACT.includes(keyLower)) copy[key] = '[REDACTED]'
      else if (keyLower === 'req' || keyLower === 'request')
        copy[key] = sanitize(simplifyRequest(value), hash, depth + 1)
      else copy[key] = sanitize(value, hash, depth + 1)
    }
    return copy
  } else {
    return obj
  }
}

function simplifyRequest(req: any): any {
  if (typeof req !== 'object' || req === null) return req

  // Do not extract the body, it may contain sensitive information
  const fields = ['cookies', 'headers', 'method', 'url'] as const

  const hasFields: number[] = fields.map((f) => (req[f] ? 1 : 0))
  const count = hasFields.reduce((sum, n) => sum + n, 0)
  const passesDuckTyping = count >= fields.length - 2
  if (!passesDuckTyping) return req

  const copy = fields.reduce((copy, field) => {
    if (req[field]) {
      const value = req[field]
      if (value instanceof Headers) copy[field] = headersToObject(value)
      else copy[field] = value
    }
    return copy
  }, {} as any)

  return copy
}

function headersToObject(headers: Headers): Record<string, string> {
  const result: Record<string, string> = {}
  headers.forEach((value, key) => (result[key] = value))
  return result
}
