import React, { useCallback, useContext, useState } from 'react'
import { getCookie, setCookie } from 'cookies-next'
import { useRouter } from 'next/router'
import { ENABLE_BETA_LANGUAGE_COOKIE } from '@/constants/cookies'
import { Durations } from '@/constants/durations'
import locales from '@/constants/locales'
import { theatricalBasePaths, theatricalProjects } from '@/constants/theatricalUtils'
import { LanguageModal } from '@/molecules/LanguageSelector/LanguageModal'
import { isFirstPathParamLocale } from '@/utils/LocaleUtil/LocaleUtil'

interface LanguagesContextProps {
  betaLanguages: string[]
  publicLanguages: string[]
  betaLanguagesEnabled: boolean
  languagesEnabled: boolean
  saveLanguage: (language: string) => void
  toggleBetaLanguages: (enable: boolean) => void
}

interface LanguagesProviderProps {
  children: React.ReactNode
}

const LanguagesContext = React.createContext<LanguagesContextProps>({
  betaLanguages: [],
  betaLanguagesEnabled: false,
  languagesEnabled: false,
  publicLanguages: [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  saveLanguage: (language: string) => undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toggleBetaLanguages: (enable: boolean) => undefined,
})

interface BetaLanguageSetting {
  enabled?: boolean
  language?: string
}

export const LanguagesProvider = ({ children }: LanguagesProviderProps) => {
  const savedBetaLanguageCookie: BetaLanguageSetting = JSON.parse(
    (getCookie(ENABLE_BETA_LANGUAGE_COOKIE) as string) ?? '{}',
  )

  const { push, asPath, pathname, query } = useRouter()
  const [enableBetaLanguages, setEnableBetaLanguages] = useState(savedBetaLanguageCookie.enabled || false)
  const [isLanguagesOpen, setIsLanguagesOpen] = useState(false)

  const handleCloseLanguageModel = useCallback(() => {
    setIsLanguagesOpen(false)
  }, [setIsLanguagesOpen])

  const handleSettingLocale = useCallback(
    (locale: string) => {
      setCookie('NEXT_LOCALE', locale, { maxAge: Durations.TEN_YEARS_IN_SECONDS })
      setCookie(ENABLE_BETA_LANGUAGE_COOKIE, JSON.stringify({ enabled: enableBetaLanguages }), {
        maxAge: Durations.TEN_YEARS_IN_SECONDS,
      })
      const isPathWithLocale = isFirstPathParamLocale(asPath)
      const pathnameSplit = window?.location?.pathname.split('/')
      if (isPathWithLocale) {
        pathnameSplit[1] = locale
        const localizedPathname = pathnameSplit.join('/')
        delete query?.langSet
        push({ pathname: localizedPathname, query }, undefined, { shallow: false })
      } else {
        pathnameSplit.splice(1, 0, locale)
        const localizedPathname = pathnameSplit.join('/')
        delete query?.langSet
        push({ pathname: localizedPathname, query }, undefined, { shallow: false })
      }
    },
    [enableBetaLanguages, asPath, push, query],
  )

  const handleToggleBetaLanguages = useCallback(
    (show: boolean) => {
      setCookie(ENABLE_BETA_LANGUAGE_COOKIE, JSON.stringify({ enabled: show }), {
        maxAge: Durations.TEN_YEARS_IN_SECONDS,
      })
      setEnableBetaLanguages(show)
    },
    [setEnableBetaLanguages],
  )

  const basePath = pathname.split('/')?.[2]

  const isTheatricalPath =
    theatricalBasePaths.some((path) => basePath?.startsWith(path)) &&
    theatricalProjects.some((project) => asPath.includes(project))

  const publicLanguages = isTheatricalPath ? [...locales.public, ...locales.theatricalLocales] : locales.public || []
  const totalLanguages = enableBetaLanguages ? publicLanguages.length + locales.beta.length : publicLanguages.length

  const contextValue = {
    betaLanguages: [...locales.beta, ...locales.theatricalLocales],
    publicLanguages: publicLanguages.sort(),
    betaLanguagesEnabled: enableBetaLanguages && locales.beta.length > 0,
    languagesEnabled: totalLanguages > 1,
    saveLanguage: handleSettingLocale,
    toggleBetaLanguages: handleToggleBetaLanguages,
  }

  return (
    <LanguagesContext.Provider value={contextValue}>
      {children}
      <LanguageModal onClose={handleCloseLanguageModel} show={isLanguagesOpen} />
    </LanguagesContext.Provider>
  )
}

export const useLanguages = () => {
  const languageContext = useContext(LanguagesContext)

  return {
    ...languageContext,
  }
}
