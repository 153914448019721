import classNames from 'classnames'
import { Button } from '@/atoms/Button'
import { SubmitProps } from './types'

export const Submit: React.FC<SubmitProps> = ({
  className = '',
  disabled = false,
  labelCancel = 'Cancel',
  labelSubmit = 'Submit',
  onCancel,
  onSubmit,
}) => {
  return (
    <div className={classNames('flex gap-4 *:flex-1', className)}>
      <Button className="h-12" disabled={disabled} onClick={onSubmit} type="button" variant="black">
        {labelSubmit}
      </Button>
      <Button className="h-12" onClick={onCancel} type="button" variant="lightgray">
        {labelCancel}
      </Button>
    </div>
  )
}
