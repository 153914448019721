import React from 'react'
import { Image } from '@/atoms/Image'
import { scaleDimensions, getHexColor } from '../utils'
import { OneColorLogoProps } from './LogoTypes'

export const AngelLogo: React.FC<OneColorLogoProps> = ({ height = 32, color = 'white', className }) => {
  const { width: w, height: h } = scaleDimensions(height, 90, 32)
  const hex = getHexColor(color)
  const fill = hex.split('#')[1]
  const version = color === 'white' ? 'v1637342011' : 'v1637341948'
  const name = color === 'white' ? 'angel_only_white' : 'angel_only_black'

  return (
    <Image
      width={w}
      height={h}
      src={`/e_colorize:100,co_rgb:${fill}/${version}/angel-studios/logos/${name}.svg`}
      className={className}
      alt="Angel Logo"
    />
  )
}
