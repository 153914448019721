import React from 'react'
import classNames from 'classnames'
import { ButtonSize, getButtonSizeClasses } from '@/atoms/Button/ButtonUtil/ButtonUtil'
import { ButtonVariant, getVariantClasses } from './ButtonUtil'

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant: ButtonVariant
  size?: ButtonSize
  outline?: boolean
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, size = 'standard', outline, className, ...props }, ref) => {
    return (
      <button
        {...props}
        ref={ref}
        className={
          variant === 'plain'
            ? className
            : classNames(
                'btn',
                getButtonSizeClasses(size),
                getVariantClasses({ variant, outline, disabled: props.disabled }),
                className,
              )
        }
      >
        {props.children}
      </button>
    )
  },
)

Button.displayName = 'Button'
