import dynamic, { DynamicOptions } from 'next/dynamic'
import { logger } from '@/utils/logging'

export const UpdatedLegalDocsModal = dynamic(
  import('./UpdatedLegalDocsModal')
    .then((mod) => mod.UpdatedLegalDocsModal)
    .catch((err) => logger().error('Failed to load the UpdatedLegalDocsModal!', err)) as DynamicOptions,
  {
    ssr: false,
  },
)
