import { useCallback, useEffect, useMemo, useState } from 'react'
import { getCookie } from 'cookies-next'
import { AUTH_JWT_COOKIE } from '@/constants/cookies'
import { FetchJsonState, useFetchJson, useFetchText } from '@/utils/fetch'
import { logger } from '@/utils/logging'
import { getStringOrEmptyString, getValidString } from '@/utils/primitives/strings'
import { useUser } from '../UserService'

const ELLIS_CLIENT_ID = 'angel_web'

export interface LegalDocument {
  id: string
  version: number
  title: string
  source: string
  required?: boolean
  type?: 'terms_of_use' | 'privacy_policy' | 'vppa'
}

export function useUnacceptedLegalDocs(): FetchJsonState & { legalDocuments: LegalDocument[] } {
  const { isLoggedIn } = useUser()

  const result = useFetchJson('', {}, { defer: true })

  useEffect(() => {
    if (!isLoggedIn) return
    if (result.loading || result.response || result.error) return

    const ellisBase = getStringOrEmptyString(process.env.NEXT_PUBLIC_ELLIS_BACKEND_URL)
    const url = `${ellisBase}/documents/to-accept?client_id=${ELLIS_CLIENT_ID}&include_optional=true`
    const accessToken = getStringOrEmptyString(getValidString(getCookie(AUTH_JWT_COOKIE)))

    const headers = new Headers()
    headers.append('Authorization', accessToken)
    headers.append('Content-Type', 'application/json')

    result.refetch(url, { headers })
  }, [isLoggedIn, result])

  return useMemo(() => {
    return { ...result, legalDocuments: validateLegalDocs(result.json) }
  }, [result])
}

function validateLegalDocs(json: unknown): LegalDocument[] {
  if (!Array.isArray(json)) return []

  const validLegalDocuments = json
    .map((legalDoc) => {
      if (
        typeof legalDoc === 'object' &&
        typeof legalDoc.id === 'string' &&
        typeof legalDoc.version === 'number' &&
        typeof legalDoc.title === 'string' &&
        typeof legalDoc.source === 'string' &&
        (legalDoc.required === undefined || legalDoc.required === null || typeof legalDoc.required === 'boolean') &&
        (legalDoc.type === undefined || legalDoc.type === null || typeof legalDoc.type === 'string')
      ) {
        return legalDoc
      } else {
        logger().error('Legal document was returned without the required properties!', { legalDoc })
        return null
      }
    })
    .filter(Boolean) as LegalDocument[]

  return validLegalDocuments
}

export function useAcceptLegalDocs() {
  const result = useFetchText('', {}, { defer: true })

  const acceptLegalDocs = useCallback(
    (legalDocs: LegalDocument[]) => {
      const ellisBase = getStringOrEmptyString(process.env.NEXT_PUBLIC_ELLIS_BACKEND_URL)
      const url = `${ellisBase}/documents/accept`
      const accessToken = getStringOrEmptyString(getValidString(getCookie(AUTH_JWT_COOKIE)))

      const headers = new Headers()
      headers.append('Authorization', accessToken)
      headers.append('Content-Type', 'application/json')

      const ids = legalDocs.map((d) => d.id)
      const body = JSON.stringify({ document_ids: ids })

      result.refetch(url, { method: 'POST', headers, body })
    },
    [result],
  )

  return useMemo(() => {
    return { ...result, acceptLegalDocs }
  }, [result, acceptLegalDocs])
}

export const useLegalDocumentsByClient = () => {
  const url = `${process.env.NEXT_PUBLIC_ELLIS_BACKEND_URL}/clients/${ELLIS_CLIENT_ID}/documents?include_optional=true`
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  const { json } = useFetchJson<LegalDocument[]>(url, { headers })

  return useMemo(() => {
    const legalDocuments = validateLegalDocs(json)
    const requiredLegalDocs = legalDocuments.filter((doc) => doc.required)
    const optionalLegalDocs = legalDocuments.filter((doc) => !doc.required)
    return { legalDocuments: legalDocuments, requiredLegalDocs, optionalLegalDocs }
  }, [json])
}

/**
 * The Video Privacy Protection Act (VPPA) states that it is illegal to disclose personally identifying information (PII)
 * to third parties of video streaming, video subscription, video rental, or video sale.
 *
 * A complaint was made against Angel Studios in July 2024 for being in violation of this law. Updates, including this function,
 * were made to the code to ensure that no PII is sent to third parties without documented consent from the user.
 *
 * See https://www.notion.so/angelstudios/VPPA-Compliance-b1f3b35c0f5d4d6e82b043d4791d465a
 */
export function useHasVPPAConsent() {
  const { isLoggedIn } = useUser()
  const [hasConsent, setHasConsent] = useState(false)

  const result = useFetchJson('', {}, { defer: true })

  useEffect(() => {
    if (!isLoggedIn) return
    if (result.loading || result.response || result.error) return

    const ellisBase = getStringOrEmptyString(process.env.NEXT_PUBLIC_ELLIS_BACKEND_URL)
    const url = `${ellisBase}/documents/status?type=vppa`
    const accessToken = getStringOrEmptyString(getValidString(getCookie(AUTH_JWT_COOKIE)))

    const headers = new Headers()
    headers.append('Authorization', accessToken)
    headers.append('Content-Type', 'application/json')

    result.refetch(url, { headers })
  }, [isLoggedIn, result])

  useEffect(() => {
    if (typeof result?.json?.accepted === 'boolean') setHasConsent(result.json.accepted)
  }, [result.json])

  return hasConsent
}
