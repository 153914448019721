import React from 'react'
import { getHexColor, OneColorIconProps } from '@/atoms/utils'

export const CreditCardIcon: React.FC<OneColorIconProps> = ({ size = 24, className, color = 'black' }) => {
  const c = getHexColor(color)

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66675 9.33328C2.66675 7.12414 4.45761 5.33328 6.66675 5.33328H25.3334C27.5426 5.33328 29.3334 7.12414 29.3334 9.33328V22.6666C29.3334 24.8758 27.5426 26.6666 25.3334 26.6666H6.66675C4.45761 26.6666 2.66675 24.8758 2.66675 22.6666V9.33328ZM6.66675 7.43328C5.61741 7.43328 4.76675 8.28394 4.76675 9.33328V22.6666C4.76675 23.716 5.61741 24.5666 6.66675 24.5666H25.3334C26.3828 24.5666 27.2334 23.716 27.2334 22.6666V9.33328C27.2334 8.28394 26.3828 7.43328 25.3334 7.43328H6.66675Z"
        fill={c}
      />
      <path d="M4.00008 10H28.0001V14H4.00008V10Z" fill={c} />
      <path
        d="M7.00008 21C7.00008 20.4477 7.4478 20 8.00008 20H10.0001C10.5524 20 11.0001 20.4477 11.0001 21C11.0001 21.5523 10.5524 22 10.0001 22H8.00008C7.4478 22 7.00008 21.5523 7.00008 21Z"
        fill={c}
      />
      <path
        d="M13.0001 21C13.0001 20.4477 13.4478 20 14.0001 20H20.0001C20.5524 20 21.0001 20.4477 21.0001 21C21.0001 21.5523 20.5524 22 20.0001 22H14.0001C13.4478 22 13.0001 21.5523 13.0001 21Z"
        fill={c}
      />
    </svg>
  )
}
