import dynamic, { DynamicOptions } from 'next/dynamic'
import { LanguageMenuProps } from '@/molecules/RegionSelector/RegionSelectorComponent'
import { logger } from '@/utils/logging'

export const RegionSelector = dynamic(
  import('./RegionSelectorComponent')
    .then((mod) => mod.RegionSelectorComponent)
    .catch((err) => logger().error(`Failed to load the RegionSelector!`, err)) as DynamicOptions<LanguageMenuProps>,
  { ssr: false },
)
