// Note, renaming useTranslation to useTranslationNext intentionally makes it so the i18n-parser won't parse this file.
// By design, the i18n-parser will throw a warning, which breaks our build, when a variable is provided.
// In this case, we really do want to pass in the namespace as a variable, because we've created the wrapper function.
import { useTranslation as useTranslationNext } from 'next-i18next'
import { useRouter } from 'next/router'
import { useLocale } from '@/utils/LocaleUtil'
import { reportErrorToBugsnag } from '../bugsnag'

export type TranslationNamespace =
  | 'about'
  | 'account'
  | 'ads'
  | 'ambassador'
  | 'app-promo'
  | 'category'
  | 'chatbot'
  | 'collectibles'
  | 'collectibles-marketplace'
  | 'coming-soon'
  | 'common'
  | 'create'
  | 'early-access'
  | 'features'
  | 'filmmaker'
  | 'guild'
  | 'guild-benefits'
  | 'guild-create'
  | 'guild-merch'
  | 'guild-voting'
  | 'marketing-gift'
  | 'home'
  | 'impact'
  | 'join-the-guild'
  | 'livestream'
  | 'notification-preferences'
  | 'partnership'
  | 'pif'
  | 'pt-announcement'
  | 'redeem'
  | 'sign-up'
  | 'sight-announcement'
  | 'theatrical-presales'
  | 'theatrical-exclusive-scenes'
  | 'the-shift-exclusive'
  | 'tickets'
  | 'torches'
  | 'viewer-profiles'
  | 'watch'
  | 'world-tour'

export function useTranslateInternal(namespace: TranslationNamespace) {
  const { pathname } = useRouter()
  const { locale } = useLocale()
  const result = useTranslationNext(namespace)

  if (locale && result) {
    const b = result.i18n?.getResourceBundle?.(locale, namespace)
    if (!b) {
      const currentPath = pathname.split('?')[0]
      reportErrorToBugsnag(
        `The locale "${locale}" for translations for this namespace "${namespace}" have not been loaded! Add this namespace to the "loadTranslations" function for this page: "${currentPath}".`,
      )
    }
  }

  return result
}
