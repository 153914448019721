import { getCookie } from 'cookies-next'
import { SEGMENT_ANONYMOUS_ID_COOKIE } from '@/constants/cookies'
import { getStringFromLocalStorage, removeFromLocalStorage } from '@/utils/local-storage'
import { type AnalyticsPlugin } from './types'

// Segment user is always set in middleware with a cookie, so we need to sync it on init of David Wells analytics library.
// Setting syncAnonymousId to true is meant to sync the anonymousId across both segment and David's library, BUT it's not working
// on first page load (David lib and Segment have two different anonymous ID's). This custom plugin
// intercepts and overrides the anonymous ID as suggested here: https://github.com/DavidWells/analytics/issues/258
export function customAnonIdPlugin() {
  const plugin: AnalyticsPlugin = {
    name: 'set-anon-id',
    bootstrap: () => {
      /**
       * in addition to the setItemStart, we need to make sure to keep the localStorage that Segment uses in sync
       * with the cookie. We need the cookie for server side and middleware experiments.
       * We want to use the cookie value because it gets cleared and refreshed when a user logs out
       * which is the desired behavior because we don't know if it's the same person anymore.
       * so, if the cookie doesn't match localstorage when we start up, we should remove the localstorage entry.
       * i tried setting the localstorage entry instead, but that did not have the desired effect.
       * by removing it though, it does get set correctly from the setItemStart method in the same request/page load
       */
      if (typeof localStorage !== 'undefined') {
        const anonId = getCookie(SEGMENT_ANONYMOUS_ID_COOKIE)

        if (typeof anonId === 'string') {
          const localAnonId = getStringFromLocalStorage('__anon_id')
          const localAjsAnonymousId = getStringFromLocalStorage(SEGMENT_ANONYMOUS_ID_COOKIE)

          if ((localAnonId && localAnonId !== anonId) || (localAjsAnonymousId && localAjsAnonymousId !== anonId)) {
            removeFromLocalStorage('__anon_id')
            removeFromLocalStorage('__user_id')
            removeFromLocalStorage('__user_traits')
            removeFromLocalStorage('ajs_anonymous_id')
            removeFromLocalStorage('ajs_user_id')
            removeFromLocalStorage('ajs_user_traits')
          }
        }
      }
    },
    setItemStart: ({ payload }: { payload: { key: string } }) => {
      if (payload.key === '__anon_id' || payload.key === 'ajs_anonymous_id') {
        const anonId = getCookie(SEGMENT_ANONYMOUS_ID_COOKIE)
        if (anonId) {
          return {
            ...payload,
            ...{ value: anonId },
          }
        }
      }
    },
  }

  return plugin
}
