import type { FC } from 'react'
import { getCookie, setCookie } from 'cookies-next'
import { FULL_STORY_ENABLED_COOKIE } from '@/constants/cookies'
import { Durations } from '@/constants/durations'
import { isClient } from '@/utils/client'
import { isProductionEnvironment } from '@/utils/environment-utils'
import { logger } from '@/utils/logging'
import { Maybe } from '@/utils/types'

const SAMPLING_RATE = 0.3
const COOKIE_MAX_AGE = Durations.THREE_MONTHS_IN_SECONDS

interface WindowWithFullStory extends Window {
  _fs_capture_on_startup?: boolean
  _fs_host?: string
  _fs_script?: string
  _fs_org?: string
  _fs_namespace?: string
  FS?: (eventName: string, payload?: Record<string, unknown>) => void
}

interface FullStoryIdentifyProperties {
  displayName: string
  email: Maybe<string>

  /**
   * https://help.fullstory.com/hc/en-us/articles/360020623254-Capture-elements-with-consent
   */
  consent?: boolean
  [key: string]: unknown
}

export async function fullStoryStart(): Promise<void> {
  if (!canRun()) return

  return whenLoaded((w) => {
    if (w.FS) w.FS('start')
  })
}

export async function fullStoryIdentify(userId: string, properties: FullStoryIdentifyProperties): Promise<void> {
  if (!canRun()) return

  return whenLoaded((w) => {
    if (w.FS) w.FS('setIdentity', { uid: userId, properties })
    return true
  })
}

export async function fullStoryTrackEvent(
  eventName: string,
  properties: Record<string, unknown>,
  schema?: { properties: Record<string, unknown> },
): Promise<void> {
  if (!canRun()) return

  return whenLoaded((w) => {
    if (w.FS) w.FS('trackEvent', { name: eventName, properties, schema })
  })
}

function canRun(): boolean {
  return isClient() && isProductionEnvironment() && shouldUserLoadFullStory()
}

export const FullStoryScript: FC = () => {
  return (
    <script
      id="full-story-script"
      dangerouslySetInnerHTML={{
        __html: /* javascript */ `
window['_fs_capture_on_startup'] = false
window['_fs_host'] = 'fullstory.com';
window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
window['_fs_org'] = 'o-1ESZ78-na1';
window['_fs_namespace'] = 'FS';
!function(m,n,e,t,l,o,g,y){var s,f,a=function(h){
return!(h in m)||(m.console&&m.console.log&&m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].'),!1)}(e)
;function p(b){var h,d=[];function j(){h&&(d.forEach((function(b){var d;try{d=b[h[0]]&&b[h[0]](h[1])}catch(h){return void(b[3]&&b[3](h))}
d&&d.then?d.then(b[2],b[3]):b[2]&&b[2](d)})),d.length=0)}function r(b){return function(d){h||(h=[b,d],j())}}return b(r(0),r(1)),{
then:function(b,h){return p((function(r,i){d.push([b,h,r,i]),j()}))}}}a&&(g=m[e]=function(){var b=function(b,d,j,r){function i(i,c){
h(b,d,j,i,c,r)}r=r||2;var c,u=/Async$/;return u.test(b)?(b=b.replace(u,""),"function"==typeof Promise?new Promise(i):p(i)):h(b,d,j,c,c,r)}
;function h(h,d,j,r,i,c){return b._api?b._api(h,d,j,r,i,c):(b.q&&b.q.push([h,d,j,r,i,c]),null)}return b.q=[],b}(),y=function(b){function h(h){
"function"==typeof h[4]&&h[4](new Error(b))}var d=g.q;if(d){for(var j=0;j<d.length;j++)h(d[j]);d.length=0,d.push=h}},function(){
(o=n.createElement(t)).async=!0,o.crossOrigin="anonymous",o.src="https://"+l,o.onerror=function(){y("Error loading "+l)}
;var b=n.getElementsByTagName(t)[0];b&&b.parentNode?b.parentNode.insertBefore(o,b):n.head.appendChild(o)}(),function(){function b(){}
function h(b,h,d){g(b,h,d,1)}function d(b,d,j){h("setProperties",{type:b,properties:d},j)}function j(b,h){d("user",b,h)}function r(b,h,d){j({
uid:b},d),h&&j(h,d)}g.identify=r,g.setUserVars=j,g.identifyAccount=b,g.clearUserCookie=b,g.setVars=d,g.event=function(b,d,j){h("trackEvent",{
name:b,properties:d},j)},g.anonymize=function(){r(!1)},g.shutdown=function(){h("shutdown")},g.restart=function(){h("restart")},
g.log=function(b,d){h("log",{level:b,msg:d})},g.consent=function(b){h("setIdentity",{consent:!arguments.length||b})}}(),s="fetch",
f="XMLHttpRequest",g._w={},g._w[f]=m[f],g._w[s]=m[s],m[s]&&(m[s]=function(){return g._w[s].apply(this,arguments)}),g._v="2.0.0")
}(window,document,window._fs_namespace,"script",window._fs_script);
    `,
      }}
    />
  )
}

function shouldUserLoadFullStory(): boolean {
  const value = getCookie(FULL_STORY_ENABLED_COOKIE)
  if (typeof value !== 'undefined' && value !== null) return Boolean(value)

  if (SAMPLING_RATE <= Math.random()) {
    setCookie(FULL_STORY_ENABLED_COOKIE, true, { maxAge: COOKIE_MAX_AGE })
    return true
  } else {
    setCookie(FULL_STORY_ENABLED_COOKIE, false, { maxAge: COOKIE_MAX_AGE })
    return false
  }
}

function whenLoaded(callback: (w: WindowWithFullStory) => void): Promise<void> {
  return new Promise<void>((resolve) => {
    const startTime = Date.now()
    const maxWaitTime = Durations.FIVE_MINUTES_IN_MILLISECONDS
    const checkFullStory = setInterval(() => {
      const w = window as unknown as WindowWithFullStory
      if (typeof w['FS'] !== 'undefined' && typeof w['FS'] === 'function') {
        callback(w)
        clearInterval(checkFullStory)
        resolve()
      } else if (Date.now() - startTime > maxWaitTime) {
        logger().warn('FullStory failed to load within the expected time frame')
        clearInterval(checkFullStory)
        resolve()
      }
    }, 10)
  })
}
