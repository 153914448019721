export function isValidDate(d: unknown): d is Date {
  return d instanceof Date && !isNaN(d.getTime())
}

export function isPast(d: Date, now = new Date()): boolean {
  return d.getTime() - now.getTime() < 0
}

/**
 * @returns yyyy-mm-dd-formatted date string (no timestamp)
 */
export function formatIso8601Date(date: Date): string {
  return isValidDate(date) ? date.toISOString().split('T')[0] : date
}

export function isValidIso8601String(dateStr: string): boolean {
  const iso8601Regex = /^(\d{4}-\d{2}-\d{2}([Tt](\d{2}:\d{2}:\d{2}(\.\d+)?([Zz]|([+-]\d{2}:\d{2})))?)?)?$/
  return iso8601Regex.test(dateStr)
}

// We get a minified React Error #31 if new Date().getFullYear().toString() is used directly so this is a workaround to quiet the logs
export function getCurrentYear(): string {
  return '2025'
}
