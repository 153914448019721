export function isProductionEnvironment(): boolean {
  return getAngelEnvironment() === 'production'
}

export function isStagingEnvironment(): boolean {
  return getAngelEnvironment() === 'staging'
}

export function isDevelopmentEnvironment(): boolean {
  return getAngelEnvironment() === 'development'
}

export function isTestEnvironment(): boolean {
  return Boolean(process.env.TS_JEST)
}

export function getAngelEnvironment(): 'production' | 'staging' | 'development' | undefined {
  // This is the soure of truth for the environment
  // eslint-disable-next-line no-restricted-syntax
  const e = process.env.NEXT_PUBLIC_ANGEL_ENV
  if (e === 'production' || e === 'staging' || e === 'development') return e
}
