import { growthbook } from '@/experimentation/client'
import { getIsAngelEmployeeUser } from '@/utils/users'
import { namedLogger } from '../utils'
import { type AnalyticsPlugin } from './types'

type AnalyticsEventParams<Payload extends object = object> = {
  payload: Payload
}

const logger = namedLogger('growthbook Analytics Plugin')

export function growthbookAnalyticsPlugin() {
  const plugin: AnalyticsPlugin = {
    name: 'growthbook',

    identify: ({
      payload,
    }: AnalyticsEventParams<{
      userId?: string
      anonymousId?: string
      traits?: {
        email?: string
        isMobile?: boolean
        isDesktop?: boolean
      }
    }>) => {
      const isAngelEmployeeUser = getIsAngelEmployeeUser(payload?.traits?.email)
      growthbook.setAttributes({
        ...growthbook.getAttributes(),
        id: payload?.anonymousId,
        userId: payload?.userId,
        isMobile: payload?.traits?.isMobile,
        isDesktop: payload?.traits?.isDesktop,
        isAngelEmployeeUser,
        // TODO: what else??
      })
    },

    loaded() {
      logger.debug('Growthbook Analytics Plugin: loaded')
      return true
    },
  }

  return plugin
}
