import { useEffect, useState } from 'react'
import { Durations } from '@/constants/durations'
import { logger } from '@/utils/logging'
import { CookieConsent, OsanoConsent, WindowWithOsano } from './types'
import { getCookieConsentProvider } from './utils'

const initial = { analytics: false, essential: false, marketing: false, personalization: false }

export function useCookieConsent() {
  const [cookieConsent, setCookieConsent] = useState<CookieConsent>(initial)

  // osano
  useEffect(() => {
    const provider = getCookieConsentProvider()
    if (provider !== 'osano') return

    whenLoaded((w) => {
      const currentConsent = w?.Osano?.cm?.getConsent()
      if (currentConsent) setCookieConsent(toGenericConsent(currentConsent))

      w?.Osano?.cm?.addEventListener('osano-cm-consent-saved', (obj: OsanoConsent) => {
        setCookieConsent(toGenericConsent(obj))
      })
    })
  }, [setCookieConsent])

  return cookieConsent
}

function whenLoaded(callback: (w: WindowWithOsano) => void): Promise<void> {
  return new Promise<void>((resolve) => {
    const startTime = Date.now()
    const maxWaitTime = Durations.FIVE_MINUTES_IN_MILLISECONDS
    const checkForOsano = setInterval(() => {
      const w = window as unknown as WindowWithOsano
      if (w.Osano) {
        callback(w)
        clearInterval(checkForOsano)
        resolve()
      } else if (Date.now() - startTime > maxWaitTime) {
        clearInterval(checkForOsano)
        logger().warn('cookieManagementHooks: Osano failed to load within the expected time frame')
        resolve()
      }
    }, 10)
  })
}

function toGenericConsent(obj: OsanoConsent): CookieConsent {
  return {
    analytics: obj.ANALYTICS === 'ACCEPT',
    essential: obj.ESSENTIAL === 'ACCEPT',
    marketing: obj.MARKETING === 'ACCEPT',
    personalization: obj.PERSONALIZATION === 'ACCEPT',
  }
}
