import { EcommPayload } from '@/utils/analytics/ecommerce-events'
import { SUPPRESSED_THEATRICAL_FUNNEL_PROJECT_SLUGS } from '../constants'
import { angelPartytownPlugin } from './angel-partytown-plugin'
import { type AnalyticsPlugin } from './types'

export function theatricalAngelPartytownPlugin() {
  const _theatricalAngelPartytownPlugin = angelPartytownPlugin({
    name: 'theatrical-angel-partytown-plugin Analytics Plugin',
    writeKey: process.env.NEXT_PUBLIC_THEATRICAL_SEGMENT_KEY,
    enabledDefault: false,
    enableRegex: /^https?:\/\/[-.a-zA-Z]+(:\d+)?\/((tickets)|(movies)|(pre-sales))\/?/,
  })

  const plugin: AnalyticsPlugin = {
    ..._theatricalAngelPartytownPlugin,
    track: (params: {
      eventName: string
      payload: {
        event: string
        anonymousId: string
        properties: {
          experiments?: unknown
          funnel?: EcommPayload['funnel']
          projectSlug?: string
        }
      }
    }) => {
      const isSuppressedTheatricalFunnelProjectSlugEvent = // Suppress the theatrical funnel for the project slug
        params?.payload?.properties?.funnel === 'theatrical' &&
        params?.payload?.properties?.projectSlug &&
        SUPPRESSED_THEATRICAL_FUNNEL_PROJECT_SLUGS.includes(params?.payload?.properties?.projectSlug)

      if (isSuppressedTheatricalFunnelProjectSlugEvent) return false

      if (
        !params?.payload?.properties?.funnel ||
        (params?.payload?.properties?.funnel && !params.payload.properties.funnel.includes('theatrical'))
      ) {
        return false
      }

      _theatricalAngelPartytownPlugin?.track?.({
        ...params,
        payload: {
          ...params.payload,
          event: `${params.payload.event} - ${params.payload.properties.funnel}`,
        },
      })
    },
  }

  return plugin
}
