import { logger } from '@/utils/logging'
import type { LogFunction, Logger } from '@/utils/logging'

type NamedLogFunction = (message: string, context?: unknown, error?: Error | unknown) => void

type NamedLogger = Omit<Logger, 'debug' | 'info' | 'warn' | 'error'> & {
  debug: NamedLogFunction
  info: NamedLogFunction
  warn: NamedLogFunction
  error: NamedLogFunction
}

export function namedLogger(name: string, options?: { debug?: boolean }): NamedLogger {
  const showDebug = !!process.env.DEBUG || options?.debug || false
  const baseLogger = logger()

  return {
    ...baseLogger,
    debug: showDebug ? namedLogFunction(name, baseLogger.debug) : () => {},
    info: namedLogFunction(name, baseLogger.info),
    warn: namedLogFunction(name, baseLogger.warn),
    error: namedLogFunction(name, baseLogger.error),
  }
}

function namedLogFunction(name: string, fn: LogFunction): NamedLogFunction {
  return (message, context, error) => {
    return fn(
      `${name}: ${message || ''}`,
      {
        loggerName: name,
        ...(error instanceof Error ? {} : { error }),
        ...contextToObject(context),
      },
      error instanceof Error ? error : new Error('Unknown Error'),
    )
  }
}

function contextToObject(context: unknown) {
  if (context === undefined) return {}
  if (context === null) return {}
  if (typeof context === 'string') return { context }
  if (typeof context === 'number') return { context }
  if (typeof context === 'boolean') return { context }
  if (Array.isArray(context)) return { context }
  if (context instanceof Error) return { error: context }
  if (typeof context === 'object') return context
  return { context }
}
