import { logger } from '../logging'

export function getBooleanFromLocalStorage(key: string): boolean | undefined
export function getBooleanFromLocalStorage(key: string, defaultValue: boolean): boolean
export function getBooleanFromLocalStorage(key: string, defaultValue?: boolean): boolean | undefined {
  const value = getFromLocalStorage(key)
  if (!value) return defaultValue

  if (/true/i.test(value)) return true
  if (/false/i.test(value)) return false

  reportParseError('boolean', key, value)
  return defaultValue
}

export function getIntegerFromLocalStorage(key: string): number | undefined
export function getIntegerFromLocalStorage(key: string, defaultValue: number): number
export function getIntegerFromLocalStorage(key: string, defaultValue?: number): number | undefined {
  const value = getFromLocalStorage(key)
  if (!value) return defaultValue

  const parsed = Number.parseInt(value)
  if (isNaN(parsed)) {
    reportParseError('integer', key, value)
    return defaultValue
  }

  return parsed
}

export function getFloatFromLocalStorage(key: string): number | undefined
export function getFloatFromLocalStorage(key: string, defaultValue: number): number
export function getFloatFromLocalStorage(key: string, defaultValue?: number): number | undefined {
  const value = getFromLocalStorage(key)
  if (!value) return defaultValue

  const parsed = Number.parseFloat(value)
  if (isNaN(parsed)) {
    reportParseError('float', key, value)
    return defaultValue
  }

  return parsed
}

export function getObjectFromLocalStorage<T extends object>(key: string): T | undefined
export function getObjectFromLocalStorage<T extends object>(key: string, defaultValue: T): T
export function getObjectFromLocalStorage<T extends object>(key: string, defaultValue?: T): T | undefined {
  const value = getFromLocalStorage(key)
  if (!value) return defaultValue

  try {
    const parsed = JSON.parse(value)
    if (parsed && typeof parsed === 'object') return parsed
    else throw new Error()
  } catch (err) {
    reportParseError('object', key, value)
    return defaultValue
  }
}

export function getStringFromLocalStorage(key: string): string | undefined
export function getStringFromLocalStorage(key: string, defaultValue: string): string
export function getStringFromLocalStorage(key: string, defaultValue?: string): string | undefined {
  const value = getFromLocalStorage(key)
  return value ? value : defaultValue
}

export function writeToLocalStorage<T extends boolean | number | object | string>(key: string, value: T): void {
  if (typeof localStorage === 'undefined' || !localStorage) return

  if (value === undefined) {
    logger().warn(`Attempted to write a value of "undefined" to localStorage. Operation failed.`, { key })
  } else if (value === null) {
    logger().warn(`Attempted to write a value of "null" to localStorage. Operation failed.`, { key })
  } else {
    const strVal = typeof value === 'object' ? JSON.stringify(value) : value.toString()
    localStorage.setItem(key, strVal)
  }
}

export function removeFromLocalStorage(key: string): void {
  if (typeof localStorage === 'undefined' || !localStorage) return
  localStorage.removeItem(key)
}

function getFromLocalStorage(key: string): string | null {
  if (typeof localStorage === 'undefined' || !localStorage) return null
  return localStorage.getItem(key)
}

function reportParseError(type: string, key: string, value: string): void {
  logger().error(`Failed to parse a value from localStorage.`, { type, key, value })
}
