import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { BaseIconProps } from '@/atoms/utils'
import { Toaster } from './Toaster'

export interface ToastProviderProps {
  children: React.ReactNode
}

export interface Toast extends ShowToastOptions {
  message: string
}

export interface ShowToastOptions {
  showInMillis?: number
  shouldHideClose?: boolean
  canRepeatToasts?: boolean
  icon?: ReactElement<BaseIconProps>
  button?: ReactElement
  className?: string
}

export interface ToastContextProps {
  canShowToast: boolean
  closeToast(): void
  showToast(message: string, options?: ShowToastOptions): void
  toast?: Toast
}

export const ToastContext = React.createContext<ToastContextProps>({
  canShowToast: false,
  toast: undefined,
  showToast: () => undefined,
  closeToast: () => undefined,
})

export const useToast = () => useContext(ToastContext)

const DEFAULT_INTERVAL = 4000

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const [toast, setToast] = useState<Toast | undefined>()
  const [canShowToast, setCanShowToast] = useState(false)
  const toastedRef = useRef(new Set<string>())

  useEffect(() => {
    let dismissTimeout: NodeJS.Timeout

    if (canShowToast && toast) {
      dismissTimeout = setTimeout(() => {
        setCanShowToast(false)
      }, toast.showInMillis)
    }

    return () => {
      clearTimeout(dismissTimeout)
    }
  }, [toast, setToast, canShowToast, setCanShowToast])

  const showToast = useCallback(
    (message: string, options: ShowToastOptions) => {
      if (!message) return

      const newToast: Toast = {
        message: message,
        showInMillis: options?.showInMillis || DEFAULT_INTERVAL,
        canRepeatToasts: options?.canRepeatToasts ?? true,
        shouldHideClose: options?.shouldHideClose ?? false,
        ...options,
      }

      setToast(newToast)
      if (newToast?.canRepeatToasts) {
        setCanShowToast(true)
      } else if (!toastedRef.current.has(newToast.message)) {
        setCanShowToast(true)
        toastedRef.current.add(newToast.message)
      }
    },
    [setCanShowToast, setToast],
  )

  const closeToast = useCallback(() => {
    setCanShowToast(false)
  }, [setCanShowToast])

  const value = useMemo(() => {
    return {
      canShowToast,
      closeToast,
      showToast,
      toast,
    }
  }, [closeToast, toast, showToast, canShowToast])

  return (
    <ToastContext.Provider value={value}>
      <Toaster />
      {children}
    </ToastContext.Provider>
  )
}
