import segment from '@analytics/segment'
import { proxyServiceURLOrigin } from '@/services/Partytown'
import { ECOMMERCE_EVENTS, EcommPayload } from '@/utils/analytics/ecommerce-events'
import { namedLogger } from '../../utils'
import { CustomFacebookPixels } from './custom-facebook-pixels'
import { generateCustomFacebookPixel } from './plugins/custom-facebook-pixel-plugin'

const logger = namedLogger('segment Analytics Plugin', {
  // debug: true,
})

export function segmentPlugin() {
  const _segmentPlugin = segment({
    syncAnonymousId: true,
    writeKey: process.env.NEXT_PUBLIC_SEGMENT_KEY,
    // eslint-disable-next-line prettier/prettier
    customScriptSrc: `${proxyServiceURLOrigin}/segment/analytics.js/v1/${process.env.NEXT_PUBLIC_SEGMENT_KEY}/analytics.min.js`,
  })

  logger.debug('_segmentPlugin', { _segmentPlugin })

  const plugins = Object.entries(CustomFacebookPixels).map(([pixelName, pixelSettings]) => {
    logger.debug('registering pixel', { pixelName })
    return generateCustomFacebookPixel(pixelName, pixelSettings)
  })

  return {
    ..._segmentPlugin,
    loaded: (...args: unknown[]) => {
      try {
        const results = [
          _segmentPlugin?.loaded?.(...args),
          ...plugins.map((plugin) => {
            const loaded = plugin.isLoaded()
            logger.debug('plugin loaded', { loaded })
            return loaded
          }),
        ]
        logger.debug('results', { results })
        return results.every((result) => result)
      } catch (error) {
        logger.error('Error in loaded', undefined, error)
        return false
      }
    },
    ready: (...args: unknown[]) => {
      try {
        _segmentPlugin?.ready?.(...args)

        const results = [...plugins.map((plugin) => plugin.isLoaded())]
        logger.debug('results', { results })
        return results.every((result) => result)
      } catch (error) {
        logger.error('Error in ready', undefined, error)
        return false
      }
    },
    initialize: (...args: unknown[]) => {
      _segmentPlugin.initialize(...args)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.analytics?.ready?.(() => {
        plugins.forEach((plugin) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.analytics?.register?.(plugin)
        })
      })
    },
    track: (params: {
      payload: {
        event: string
        userId?: string
        anonymousId?: string
        properties: {
          experiments?: unknown
          funnel?: EcommPayload['funnel']
          projectSlug?: string
        }
      }
      config?: {
        disableAnonymousTraffic?: boolean
      }
    }) => {
      // node_modules/@analytics/segment/lib/analytics-plugin-segment.es.js:127 track()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const client = typeof window !== 'undefined' ? window.analytics : null
      if (!client) {
        return _segmentPlugin.track(params)
      }
      const payload = params.payload,
        config = params.config
      const userId = payload.userId,
        anonymousId = payload.anonymousId

      if (!userId && !anonymousId) {
        const error = new Error('Missing userId and anonymousId. You must include one to make segment call')
        logger.error(error.message, undefined, error)
        throw error
      }

      const data = {
        event: payload.event,
        properties: payload.properties,
        ...(userId ? { userId } : { anonymousId }),
      }

      if (config?.disableAnonymousTraffic && !userId) {
        return false
      }

      client.track(data, {
        integrations: {
          ...(!!params?.payload?.properties?.funnel &&
          !!ECOMMERCE_EVENTS.includes(params?.payload?.event as typeof ECOMMERCE_EVENTS[number])
            ? (Object.fromEntries(
                Object.entries(CustomFacebookPixels)
                  .filter(([, pixelSettings]) => pixelSettings.allowEcommerceSpecEvents !== false)
                  .map(([pixelName]) => [pixelName, false]),
              ) as object)
            : {}),
        },
      })

      // const isSuppressedTheatricalFunnelProjectSlugEvent = // Suppress the theatrical funnel for the project slug
      //   params?.payload?.properties?.funnel === 'theatrical' &&
      //   params?.payload?.properties?.projectSlug &&
      //   SUPPRESSED_THEATRICAL_FUNNEL_PROJECT_SLUGS.includes(params?.payload?.properties?.projectSlug)

      if (
        !!params.payload.event &&
        !!params?.payload?.properties?.funnel &&
        ECOMMERCE_EVENTS.includes(params?.payload?.event as typeof ECOMMERCE_EVENTS[number])
        // && !isSuppressedTheatricalFunnelProjectSlugEvent
      ) {
        const eventName = `${params.payload.event} - ${params.payload.properties.funnel}`
        client.track({ ...data, event: eventName })
      }
    },
  }
}
