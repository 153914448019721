import React from 'react'
import { DateTime } from 'luxon'
import { useLocale } from '@/utils/LocaleUtil'

interface RelativeDateDisplayOptions {
  value?: number
  unit: Intl.RelativeTimeFormatUnit
  formatOptions: Intl.RelativeTimeFormatOptions
}

const determineUnitFromDate = (date: Date): { value: number; unit: Intl.RelativeTimeFormatUnit } => {
  const duration = DateTime.fromJSDate(date).diffNow()

  let durationValue = duration.as('years')

  if (Math.abs(durationValue) >= 1) {
    return {
      value: Math.round(durationValue),
      unit: 'years',
    }
  }

  durationValue = duration.as('months')
  if (Math.abs(durationValue) < 12 && Math.abs(durationValue) >= 1) {
    return {
      value: Math.round(durationValue),
      unit: 'months',
    }
  }

  durationValue = duration.as('weeks')
  if (Math.abs(durationValue) < 5 && Math.abs(durationValue) >= 1) {
    return {
      value: Math.round(durationValue),
      unit: 'weeks',
    }
  }

  durationValue = duration.as('days')
  if (Math.abs(durationValue) < 7 && Math.abs(durationValue) >= 1) {
    return {
      value: Math.round(durationValue),
      unit: 'days',
    }
  }

  durationValue = duration.as('hours')
  if (Math.abs(durationValue) < 24 && Math.abs(durationValue) >= 1) {
    return {
      value: Math.round(durationValue),
      unit: 'hours',
    }
  }

  durationValue = duration.as('minutes')
  if (Math.abs(durationValue) < 60 && Math.abs(durationValue) >= 1) {
    return {
      value: Math.round(durationValue),
      unit: 'minutes',
    }
  }

  return { unit: 'seconds', value: Math.round(durationValue) }
}

interface RelativeTimeDisplayProps {
  children: (relativeTime: string) => React.ReactNode
  date: Date
  options?: RelativeDateDisplayOptions
}

export const RelativeTimeDisplay: React.FC<RelativeTimeDisplayProps> = ({ children, date, options }) => {
  const { locale } = useLocale()

  const unitResult =
    options?.unit && options?.value ? { value: options.value, unit: options.unit } : determineUnitFromDate(date)

  if (Number.isNaN(unitResult?.value)) {
    return null
  }

  const formatter = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' })

  const formattedTime = formatter.format(unitResult.value, unitResult.unit)
  return <>{children(formattedTime)}</>
}
